import React, {useEffect, useState, useContext, useRef} from 'react';
import {makeStyles, IconButton, Box, Popper, Paper, Grow, Typography, Modal, Grid, FormControl, Select, MenuItem, TextField, Button} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {getIssueQuestionaireFields, editIssueQuestionaireFields} from '../../requests/QuestionaireRequests';
import {deleteMedia} from '../../requests/MediaRequests';
import AlertContext from '../../context/AlertContext';
import {editIssue, getIssueMedia, deleteIssue} from '../../requests/IssueRequests';
import {withStyles} from '@material-ui/core/styles';
import {DropzoneAreaBase} from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {useFormik} from 'formik';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import ConfirmDialog from '../ConfirmDialog';
import CommentsSection from '../CommentPage/CommentsSection';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CircularProgress from '@mui/material/CircularProgress';
import {ReactComponent as CustomarrowDown} from '../../customArrowDown.svg';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import '../../css/GlobalStyle.css';

function IssueDetail({visible, onCancel, issue}) {
  const classes = useStyles();
  const [fields, setFields] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [title, setTitle] = useState();
  const [initialValues, setInitialValues] = useState();
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaToDelete, setMediaToDelete] = useState([]);
  const [clickedFile, setClickedFile] = useState();
  const [confirmOpen, setConfirmOpen]= useState(false);
  const [clickedIssue, setClickedIssue] = useState();
  const {alert} = useContext(AlertContext);
  const [key, setKey] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModalOpen = () =>{
    setOpen(false);
  };

  let firstTime = true;

  useEffect(async () => {
    if (visible && firstTime) {
      firstTime = false;
      loadFields(issue);
    }
  }, [visible]);

  useEffect(() => {
    function setHeight() {
      const tx = document.getElementsByClassName('subjectTextAreas');
      for (let i = 0; i < tx.length; i++) {
        tx[i].setAttribute('style', 'height: 0px');
        tx[i].setAttribute('style', 'height:' + (tx[i].scrollHeight) + 'px;overflow-y:hidden;');
        tx[i].addEventListener('input', OnInput, false);
      }
    }
    function OnInput() {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight) + 'px';
    }

    setHeight();
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setWaiting(true);
      handleSubmit(values);
    },
  });

  const handleDeleteIssue = async (issue) => {
    await deleteIssue(issue.id, alert);
    onCancel();
  };

  const handleDeleteMedia = (file) => {
    if (confirmOpen == true) {
      setMediaToDelete((mediaToDelete) => [...mediaToDelete, file]);
      const array = [...media];
      const index = array.indexOf(file);
      if (index !== -1) {
        array.splice(index, 1);
        setMedia(array);
      }
    }
  };

  const loadFields = async (issue) => {
    setFiles([]);
    setSelectedFiles([]);
    const fieldsdata = await getIssueQuestionaireFields(issue.id, alert);
    const values = {};
    fieldsdata.forEach((field) => {
      values[field.name] = field.pivot.answer ?? '';
    });
    values['subject'] = issue.subject;
    values['description'] = issue.description;
    setInitialValues(values);
    setTitle(issue.subject);
    setFields(fieldsdata);
    const media = await getIssueMedia(issue.id, alert);
    setMedia(media);
    setLoading(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const setClickedMedia = (file) => {
    setConfirmOpen(true);
    setClickedIssue();
    setClickedFile(file);
  };

  const setClickedTicket = () => {
    setConfirmOpen(true);
    setClickedFile();
    setClickedIssue(issue);
  };

  const handleSubmit = async (values) => {
    if (mediaToDelete.length > 0) {
      for (const file of mediaToDelete) {
        await deleteMedia(file.file.id, alert);
      }
    }
    setMediaToDelete([]);

    values.status_id = 1;

    const response = await editIssue(issue.id, values, files, issue.epic_id, null, alert);

    if (response != null) {
      const fieldsResponse = await editIssueQuestionaireFields(issue.id, values, fields, alert);
      if (fieldsResponse != null) {
        setKey(key + 1);
        loadFields(response.data.issue);
        setTitle(response.data.issue.subject);
        alert({
          title: `Issue successfully updated`,
          severity: 'success',
        });
      }
      setWaiting(false);
    }
  };

  const content = <Grid className="contentContainer" item xs={12} sm={12} md={6}>
    <form onSubmit={formik.handleSubmit} className="divider">
      <Box>
        {fields.length ?
          <div className={issue.status_id > 1 ? 'textAreas divider textAreas-disabled' : 'textAreas divider textAreas-active'}>
            <FormControl variant="standard" disableUnderline fullWidth>
              <StyledTextField
                id="subject"
                label=""
                name="subject"
                multiline
                rows="auto"
                variant="standard"
                disabled={issue.status_id > 1}
                required
                onChange={formik.handleChange}
                value={formik.values['subject']}
                InputProps={{
                  readOnly: (issue.status_id > 1),
                  disableUnderline: true,
                  inputProps: {className: 'subjectTextAreas'},
                }}
                inputProps={{maxLength: 255}}
              >
              </StyledTextField>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <StyledTextField
                id="description"
                label=""
                name="description"
                multiline
                minRows={9}
                disabled={issue.status_id > 1}
                disableUnderline
                variant="standard"
                onChange={formik.handleChange}
                value={formik.values['description']}
                InputProps={{
                  readOnly: (issue.status_id > 1),
                  disableUnderline: true,
                }}
              >
              </StyledTextField>
            </FormControl>
          </div> : <span></span>
        }
        <div className={issue.status_id > 1 ? 'extraFields extraFields-disabled' : 'extraFields extraFields-active'}>
          {Object.values(fields).map((field) => (
            renderFields(field, formik, issue)
          ))}
          {fields.length && issue.status_id <= 1 ?
            <FormControl fullWidth className={classes.marginBotTop && 'dropzoneContainer'}>
              <DropzoneAreaBase key={key}
                fileObjects={selectedFiles}
                acceptedFiles={['.jpg', '.mp4', '.png', '.jpeg', '.mov']}
                dropzoneClass={classes.dropZone}
                onAdd={(newFileObjs) => {
                  setSelectedFiles([].concat(selectedFiles, newFileObjs));
                  for (const newFile of newFileObjs) {
                    setFiles((files) => [...files, newFile.file] );
                  }
                }}
                onDelete={(newFileObjs) => {
                  setFiles(files.filter((file) => file.name !== newFileObjs.file.name));
                  setSelectedFiles(selectedFiles.filter((file) => file.file.name !== newFileObjs.file.name));
                }}
                filesLimit={5}
                showPreviews={true}
                useChipsForPreview={true}
                previewText={'Files'}
                showPreviewsInDropzone={false}
                maxFileSize={8000000}
                showAlerts={true}
                alertSnackbarProps={{anchorOrigin: {vertical: 'top', horizontal: 'center'},
                  classes: {root: 'alertContainer'}}}
              />
            </FormControl> :
            <span></span>
          }
          <Grid container direction="row" className="previewContainer" justifyContent='flex-start'>
            {media.length ? Object.values(media).map((med) => (
              <Grid item className={classes.setMedia} key={med.file.id}>
                <Box justifyContent='center'>
                  { med.file.extension == 'mp4' || med.file.extension == 'mov' ?
              <video autoPlay loop muted width={100} height={75}
                onClick={()=> window.open(med.url, med.file.name)}>
                <source src={med.url} type="video/mp4"/>
                <img src={med.url} />
              </video> :
              <img width={100} height={75}
                key={med.file.url}
                src={med.url}
                onClick={()=> window.open(med.url, med.file.name)}
              />
                  }
                  {issue.status_id <= 1 ?
                <IconButton onClick={() => setClickedMedia(med)} size="small">
                  <ClearRoundedIcon fontSize="small"/>
                </IconButton> :
                <span></span>
                  }
                </Box>
              </Grid>
            )) : <span></span> }
            { clickedFile && confirmOpen ?
              <ConfirmDialog
                title="Are u sure u want to remove this file?"
                description={clickedFile.file.name}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => handleDeleteMedia(clickedFile)}
              ></ConfirmDialog> : <span></span>
            }
          </Grid>
          {fields.length && issue.status_id <= 1 ?
            <div className="submitButton">
              { waiting ?
                <Typography align='center'>
                  <CircularProgress className={classes.loadIcon}/>
                </Typography> :
                <Typography align='center'>
                  <StyledButton
                    type="submit"
                    className="button-block"
                  >
                  Save
                  </StyledButton>
                </Typography>
              }
            </div> :
            <span></span>
          }
        </div>
        <div id="loadIconContainer">
          { loading ?
            <Typography align='center'>
              <CircularProgress className={classes.loadIcon}/>
            </Typography> :
            <span></span>
          }
        </div>
      </Box>
    </form>
  </Grid>;

  function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{p: 3}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      'id': `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box id="issueDetail" sx={style}>
        {isMobile ?
        <Grid className="issueContainer">

          <Grid className="issueNavBar" container direction="row" justifyContent='space-between'>
            <IconButton onClick={onCancel}>
              <ArrowBackIosNewRoundedIcon fontSize="small"/>
            </IconButton>

            <div>
              <Typography className={classes.header} style={{color: issue.epic.color.secondary_color, backgroundColor: issue.epic.color.primary_color, borderColor: issue.epic.color.secondary_color}}>
                {issue.epic.name}
              </Typography>
            </div>

            <IconButton
              ref={anchorRef}
              id="composition-icon"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <MoreVertIcon fontSize="large"/>
            </IconButton>
            <Popper
              id="menuDropdownMobile"
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({TransitionProps, placement}) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <Box className="tabLabelsContainer" sx={{borderBottom: 1, borderColor: 'divider'}} id="composition-menu"
                        aria-labelledby="composition-icon">
                        <Tabs orientation="vertical" value={value} onChange={handleChange} aria-label="basic tabs example">
                          <Tab label="Content" {...a11yProps(0)} onClick={handleModalOpen}/>
                          <Tab label="Chat" {...a11yProps(1)} onClick={handleModalOpen}/>
                        </Tabs>
                      </Box>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

          </Grid>
          { clickedIssue && confirmOpen ?
            <ConfirmDialog
              title="Are u sure u want to delete this issue?"
              description={clickedIssue.subject}
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={() => handleDeleteIssue(clickedIssue)}
            ></ConfirmDialog> : <span></span>
          }
          <br></br>

          <TabPanel className="tabContainer content" value={value} index={0}>
            <Grid className="issueContainer">
              {content}
            </Grid>
          </TabPanel>
          <TabPanel className="tabContainer chat" value={value} index={1}>
            <Grid item xs={12} sm={12} className={issue.status_id <= 1 ? 'commentBase unavailable' : 'commentBase'}>
              { issue.status_id <= 1 ?
                <p className="commentsUnavailable" align='center'>Drag issue to other column to use comments</p> :
                <CommentsSection
                  issue={issue}
                />
              }
            </Grid>
          </TabPanel>
        </Grid> :
        <Grid container direction="row" justifyContent='center' className="issueContainer">

          <Grid container direction="row" justifyContent='space-between'>
            <div>
              <Typography className={classes.header} style={{color: issue.epic.color.secondary_color, backgroundColor: issue.epic.color.primary_color, borderColor: issue.epic.color.secondary_color}}>
                {issue.epic.name}
              </Typography>
            </div>
            { fields.length && issue.status_id == 1 ?
              <IconButton onClick={() => setClickedTicket()}>
                <DeleteOutlineRoundedIcon fontSize="small"/>
              </IconButton> : <span></span>
            }
            <IconButton onClick={onCancel}>
              <CloseIcon/>
            </IconButton>
          </Grid>
          { clickedIssue && confirmOpen ?
          <ConfirmDialog
            title="Are u sure u want to delete this issue?"
            description={clickedIssue.subject}
            open={confirmOpen}
            setOpen={setConfirmOpen}
            onConfirm={() => handleDeleteIssue(clickedIssue)}
          ></ConfirmDialog> : <span></span>
          }
          <br></br>
          {content}
          <Grid item xs={12} sm={6} className={issue.status_id <= 1 ? 'commentBase unavailable' : 'commentBase'}>
            { issue.status_id <= 1 ?
              <p className="commentsUnavailable" align='center'>Drag issue to other column to use comments</p> :
              <CommentsSection
                issue={issue}
              />
            }
          </Grid>

        </Grid>
        }
      </Box>

    </Modal>
  );
};

function renderFields(param, formik, issue) {
  const name = param.name;
  const status = issue.status_id > 1;
  switch (param.field_type) {
    case 'select':
      return <FormControl variant="standard" fullWidth key={name}>
        <Typography>{param.name}</Typography>
        <StyledSelect key={name}
          IconComponent={CustomarrowDown}
          id={name}
          label=""
          name={name}
          disableUnderline={status}
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name] || 'none'}
          MenuProps={
            {classes: {paper: 'dropDownList'}}
          }
          disabled={status}

          InputProps={{
            readOnly: (status),
            disableUnderline: (status),
          }}
        >
          {param.input && Object.values(param.input).map((input) => (
            <MenuItem key={input} value={input}>{input}</MenuItem>
          ))}
        </StyledSelect>
      </FormControl>;
    case 'text':
      return <FormControl variant="standard" fullWidth key={name}>
        <Typography>{param.name}</Typography>
        <StyledTextField key={name}
          id={name}
          label=""
          name={name}
          variant="standard"
          disableUnderline={status}
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name] || 'none'}
          disabled={status}
          InputProps={{
            readOnly: (status),
            disableUnderline: (status),
            startAdornment: (
              name.includes('laat') ?
              <InputAdornment position="start">
                <AccessTimeRoundedIcon fontSize="small" />
              </InputAdornment>:
              ''
            ),
          }}
          inputProps={{maxLength: 255}}
        >
        </StyledTextField>
      </FormControl>;
    case 'textarea':
      return <FormControl variant="standard" fullWidth className="textAreas" key={name}>
        <Typography>{param.name}</Typography>
        <StyledTextField key={name}
          id={name}
          label=""
          name={name}
          multiline
          minRows={2}
          variant="standard"
          disableUnderline
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name] || 'none'}
          disabled={status}
          InputProps={{
            readOnly: (status),
            disableUnderline: (status),
          }}
        >
        </StyledTextField>
      </FormControl>;
    case 'number':
      return <FormControl variant="standard" fullWidth key={name}>
        <Typography>{param.name}</Typography>
        <StyledTextField key={name}
          id={name}
          label=""
          name={name}
          type="number"
          variant="standard"
          disableUnderline
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name] || 0}
          disabled={status}
          InputProps={{
            readOnly: (status),
            disableUnderline: (status),
          }}
        >
        </StyledTextField>
      </FormControl>;
    default:
      return <span></span>;
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
  fontFamily: 'Quicksand',
};

const useStyles = makeStyles(() => ({
  dropZone: {
    minHeight: '150px',
  },
  select: {
    borderRadius: '80px',
  },
  itemStart: {
    display: 'flex',
    alignItems: 'top',
    borderRadius: '10px',
    backgroundColor: '#F0F0F0',
    height: '600px',
  },
  setMedia: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '10px',
  },
  header: {
    alignSelf: 'center',
    fontSize: '20px',
    fontFamily: 'Quicksand',
  },

  marginTop: {
    marginTop: '10px',
  },
  marginBotTop: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  loadIcon: {
    marginTop: '30px',
    marginBottom: '30px',
  },
}));

const StyledTextField = withStyles({
  root: {
    'borderRadius': '10px',
    'fontFamily': 'Quicksand',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        'borderRadius': '10px',
      },
    },
    '& .MuiInputBase-input': {
      'fontFamily': 'Quicksand',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      'borderRadius': '10px',
    },
  },
})(TextField);

const StyledSelect = withStyles({
  root: {
    'fontFamily': 'Quicksand',
  },
})(Select);

const StyledButton = withStyles({
  root: {
    'backgroundColor': '#F0F0F0',
    'borderRadius': '90px',
    'width': '30%',
    'marginTop': '30px',
    'marginBottom': '30px',
  },
})(Button);

export default IssueDetail;

