import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function getOrganisation(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/organisation/${id}`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function getOrganisationProjects(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/organisation/${id}/projects`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function getEpicValidationAlert(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/organisation/${id}/validationAlert`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}
