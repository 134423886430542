import React, {useContext, useEffect, useState} from 'react';
import Board, {moveCard} from '@asseinfo/react-kanban';
import {BoardCard} from './BoardCard';
import BoardColumnHeader from './BoardColumnHeader';
import UserContext from '../../context/UserContext';
import AlertContext from '../../context/AlertContext';
import {makeStyles, Grid, Box, Input, Typography} from '@material-ui/core';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {getOrganisationProjects} from '../../requests/OrganisationRequests';
import {getProjectQuestionaires, getProjectEpics} from '../../requests/ProjectRequests';
import {editIssue} from '../../requests/IssueRequests';
import {getStatuses} from '../../requests/StatusRequests';
import {useLocation} from 'react-router-dom';
import '../../css/BoardStyle.css';
import ProjectsContext from '../../context/ProjectsContext';
import EpicsContext from '../../context/EpicsContext';
import ProjectQuestionairesContext from '../../context/ProjectQuestionairesContext';
import {ReactComponent as CustomarrowDown} from '../../customArrowDown.svg';
import {ReactComponent as DropdownBlob} from '../../dropdownBlob.svg';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';

const board = {
  columns: [

  ],
};

function BoardOverview() {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const {alert} = useContext(AlertContext);
  const location = useLocation();
  const [statuses, setStatuses] = useState([]);
  const [projects, setProjects] = useState([]);
  const [epics, setEpics] = useState([]);
  const [issues, setIssues] = useState([]);
  const [controlledBoard, setBoard] = useState(board);
  const projectsContext = useContext(ProjectsContext);
  const epicsContext = useContext(EpicsContext);
  const projectQuestionairesContext = useContext(ProjectQuestionairesContext);
  const [waiting, setWaiting] = useState(true);

  const [filteredEpics, setFilteredEpics] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [projectFilterValue, setProjectFilterValue] = useState('');
  const [epicFilterValues, setEpicFilterValues] = useState([]);
  const [rerender, setRerender] = useState(true);

  function animateCSS(element, animation, prefix = 'animate__') {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelectorAll(element);
    for (let i = 0; i < node.length; i++) {
      setAnimation(node, i);
    }
    function setAnimation(elements, counter) {
      setTimeout(function() {
        elements[counter].style.display = 'block';
        elements[counter].classList.add(`${prefix}animated`, animationName);
      }, 200 * counter);
    }
  };

  function hideElements() {
    const hideCards = document.querySelectorAll('.cards');

    for (let i = 0; i < hideCards.length; i++) {
      hideCards[i].style.display = 'none';
    }

    animateCSS('.cards', 'bounceInUp');
  }

  useEffect(async () => {
    board.columns = [];
    const statuses = await getStatuses(alert);
    setStatuses(statuses);

    if (statuses && statuses.length) {
      for (const status of statuses) {
        const column = {id: status.id, title: status.name, cards: []};
        board.columns.push(column);
      }
    }

    const organisationProjects = await getOrganisationProjects(userContext.user.organisation, alert);
    projectsContext.setProjects(organisationProjects);
    setProjects(organisationProjects);

    let allProjectsEpics = [];
    let ProjectEpicIssues = [];
    if (organisationProjects && organisationProjects.length) {
      for (const organisationProject of organisationProjects) {
        const projectEpics = await getProjectEpics(organisationProject.id, alert);
        const tempEpics = [...projectEpics.projectUnArchivedEpics, ...projectEpics.projectArchivedEpics];
        allProjectsEpics = [...allProjectsEpics, ...tempEpics];
        ProjectEpicIssues = [...ProjectEpicIssues, ...projectEpics.projectIssues];
      }
      setEpics(allProjectsEpics);
      setFilteredEpics(allProjectsEpics);
      setIssues(ProjectEpicIssues);
      setFilteredIssues(ProjectEpicIssues);
      epicsContext.setAllEpics(allProjectsEpics);
    }

    if (ProjectEpicIssues && ProjectEpicIssues.length) {
      // An epic board has been selected in the overview page
      if (location.state) {
        setProjectFilterValue(location.state.epicProjectId);
        setEpicFilterValues(((epicFilterValues) => epicFilterValues.concat(location.state.epicId)));
        const questionaireData = await getProjectQuestionaires(location.state.epicProjectId, alert);
        projectQuestionairesContext.setQuestionaires(questionaireData);
        setRerender((rerender) => true);
        return;
      }

      fillBoard(ProjectEpicIssues);

      hideElements();
    }
    setWaiting(false);

    hideElements();
  }, []);

  useEffect(async () => {
    // Project broadcast events
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.ProjectUpdatedEvent', async (e) => {
      if (e.message != undefined) {
        const editedProject = e.message;
        projectsContext.setProjects((projects) => {
          return projects.map((project) => {
            return project.id === editedProject.id ? editedProject : project;
          });
        });
        setProjects((projects) => {
          return projects.filter((project) => project.id !== editedProject.id);
        });
        // Update project names from epics associated with updated Project.
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.project_id === editedProject.id ? {...epic, project: editedProject} : epic;
          });
        });
        setRerender((rerender) => true);
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.ProjectDeletedEvent', async (e) => {
      if (e.message != undefined) {
        const deletedProject = e.message;
        projectsContext.setProjects((projects) => {
          return projects.filter((project) => project.id !== deletedProject.id);
        });
        setProjects((projects) => {
          return projects.filter((project) => project.id !== deletedProject.id);
        });
        // Remove all epics associated with deleted Project.
        setEpics((epics) => {
          const deletedEpics = epics.filter((epic) => epic.project_id === deletedProject.id);
          for (const deletedEpic of deletedEpics) {
            // Remove all issues associated with deleted Project.
            setIssues((issues) => {
              return issues.filter((issue) => issue.epic_id !== deletedEpic.id);
            });
          }
          return epics.filter((epic) => epic.project_id !== deletedProject.id);
        });
        setProjectFilterValue((projectFilterValue) => {
          if (projectFilterValue === deletedProject.id) {
            return '';
          }
          return projectFilterValue;
        });
        setRerender((rerender) => true);
      }
    });
    // Epic broadcast events
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.EpicAddedEvent', (e) => {
      if (e.message != undefined) {
        const addedEpic = e.message;
        setEpics((epics) => epics.concat(addedEpic));
        setRerender((rerender) => true);
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.EpicUpdatedEvent', (e) => {
      if (e.message != undefined) {
        const editedEpic = e.message;
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.id === editedEpic.id ? editedEpic : epic;
          });
        });
        // Update epic names from issues associated with updated Epic.
        setIssues((issues) => {
          return issues.map((issue) => {
            return issue.epic_id === editedEpic.id ? {...issue, epic: editedEpic} : issue;
          });
        });
        setRerender((rerender) => true);
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.EpicDeletedEvent', (e) => {
      if (e.message != undefined) {
        const deletedEpic = e.message;
        setEpics((epics) => {
          return epics.filter((epic) => epic.id !== deletedEpic.id);
        });
        // Remove all issues associated with deleted Epic.
        setIssues((issues) => {
          return issues.filter((issue) => issue.epic_id !== deletedEpic.id);
        });
        setRerender((rerender) => true);
      }
    });
    // Issue broadcast events
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.IssueAddedEvent', (e) => {
      if (e.message != undefined) {
        const addedIssue = e.message;
        setIssues((issues) => issues.concat(addedIssue));
        setRerender((rerender) => true);
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.IssueUpdatedEvent', (e) => {
      if (e.message != undefined) {
        const editedIssue = e.message;
        setIssues((issues) => {
          return issues.map((issue) => {
            return issue.id === editedIssue.id ? editedIssue : issue;
          });
        });
        setRerender((rerender) => true);
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.IssueDeletedEvent', (e) => {
      if (e.message != undefined) {
        const deletedIssue = e.message;
        setIssues((issues) => {
          const tempIssues = issues.filter((issue) => issue.id !== deletedIssue.id);
          setProjectFilterValue((projectFilterValue) => {
            if (tempIssues.length === 0 && projectFilterValue !== '' ) {
              return '';
            }
            return projectFilterValue;
          });
          return tempIssues;
        });
        setRerender((rerender) => true);
      }
    });
  }, []);

  async function handleCardMove(movedIssue, source, destination) {
    // Position change inside same column
    if (source.fromColumnId == destination.toColumnId) {
      alert({title: 'You can not change the position of a card inside a column', severity: 'warning'});
      return;
    }

    // Set issue status id to moved to column id
    if (checkCardMove(source, destination)) {
      setBoard((controlledBoard) => {
        return moveCard(controlledBoard, source, destination);
      });
      movedIssue.status_id = destination.toColumnId;
      const response = await editIssue(movedIssue.id, movedIssue, null, null, null, alert);
      // Check if an error occured, if so then move the issue back to its original column
      if (response.data === undefined || response.data === []) {
        const newSource = {fromPosition: destination.toPosition, fromColumnId: destination.toColumnId};
        const previousSource = {toPosition: source.fromPosition, toColumnId: source.fromColumnId};
        setBoard((controlledBoard) => {
          return moveCard(controlledBoard, newSource, previousSource);
        });
        document.getElementsByClassName('cards').style.removeProperty('display');
      }
    } else {
      alert({title: 'You are not allowed to move this card to this column', severity: 'warning'});
    }
  }

  function checkCardMove(source, destination) {
    const fromStatus = statuses.find((element) => {
      return element.id === source.fromColumnId;
    });
    const destinationStatus = statuses.find((element) => {
      return element.id === destination.toColumnId;
    });
    switch (fromStatus.name) {
      case 'Backlog':
        if (destinationStatus.name == 'To Do') {
          return true;
        }
        return false;
      case 'To Do':
        if (destinationStatus.name == 'Backlog') {
          return true;
        }
        return false;
      case 'In Progress':
        return false;
      case 'Validate':
        if (destinationStatus.name == 'To Do' || destinationStatus.name == 'Done') {
          return true;
        }
        return false;
      case 'Done':
        return false;
    }
    return false;
  }

  useEffect(() =>{
    disableSpecifiedDrag();
  });

  function disableSpecifiedDrag() {
    const noDragCards = document.querySelectorAll('.react-kanban-column:nth-of-type(3) > div:nth-of-type(2) > div' && '.react-kanban-column:nth-of-type(5) > div:nth-of-type(2) > div');

    for (let i = 0; i < noDragCards.length; i++) {
      noDragCards[i].removeAttribute('data-rbd-drag-handle-draggable-id');
    }
  }

  const renderColumnHeader = ({id, title}) => (
    <BoardColumnHeader
      id={id}
      className={'columnHeaders'}
      title={title}
      projectFilterValue={projectFilterValue}
      epicFilterValues={epicFilterValues}
    />
  );

  function resetFilters() {
    setFilteredEpics(epics);
    setFilteredIssues(issues);
    fillBoard(issues);
  }

  function fillBoard(issueArray) {
    const boardCopy = controlledBoard.columns;
    for (const column of boardCopy) {
      if (issueArray.length) {
        column.cards = issueArray.filter((issue) => issue.status_id === column.id);
      } else {
        column.cards = [];
      }
    }
    setBoard({columns: boardCopy});
  }

  const handleChange = async (event) => {
    if (event.target.value === '') {
      setEpicFilterValues([]);
    } else {
      const filterEpics = epics.filter((filteredEpic) => filteredEpic.project_id === event.target.value);
      setEpicFilterValues(filterEpics.map((filterEpic) => filterEpic.id));
    }
    setProjectFilterValue(event.target.value);

    if (event.target.value != '') {
      const questionaireData = await getProjectQuestionaires(event.target.value, alert);
      projectQuestionairesContext.setQuestionaires(questionaireData);
    }

    setRerender(true);
  };

  const isAllSelected = filteredEpics.length > 0 && epicFilterValues.length === filteredEpics.length;

  useEffect(() => {
    if (rerender) {
      // All Projects have been selected
      if (projectFilterValue === '') {
        resetFilters();
        // A single Project is selected
      } else {
        // Epics filtered on selected project
        const filterEpics = epics.filter((filteredEpic) => filteredEpic.project_id === projectFilterValue);
        setFilteredEpics(filterEpics);
        let filterEpicIssues = [];
        // Epics have been selected
        if (epicFilterValues.length) {
          filterEpicIssues = issues.filter((filteredIssue) => epicFilterValues.some(
              (epicFilterValue) => epicFilterValue === filteredIssue.epic_id,
          ));
        }
        // If no epics have been selected, just return the empty filterEpicIssues array
        setFilteredIssues(filterEpicIssues);
        fillBoard(filterEpicIssues);
        setWaiting(false);
      }
      setRerender(false);
    }
  }, [rerender, projectFilterValue, epicFilterValues]);

  const handleMultiChange = (event) => {
    // All epics have been selected and the selected epics are not equal to the overal epics
    if (event.target.value.includes('all') && epicFilterValues.length !== filteredEpics.length) {
      setEpicFilterValues(filteredEpics.map((filteredEpic) => filteredEpic.id));
    // All epics or no projects have been selected and there are no selected epics
    } else if (event.target.value.includes('all')) {
      setEpicFilterValues([]);
    // One or multiple epics have been selected
    } else {
      setEpicFilterValues(event.target.value);
    }
    setRerender(true);
  };

  const getSelectValues = (selected) => {
    return filteredEpics.filter((filteredEpic) => selected.includes(filteredEpic.id)).map((record) => record.name).join(', ');
  };

  return (
    <Box id="board">
      <div>
        <Grid container direction="row" className="kanbanNavContainer" spacing={2}>
          {
          (!projectFilterValue === '' || issues.length) ?
          <>
            <Grid item>
              <FormControl variant="standard" fullWidth>
                <Select
                  labelId="select-label"
                  IconComponent={CustomarrowDown}
                  disableUnderline
                  variant="standard"
                  id="projects-select"
                  defaultValue = ""
                  displayEmpty
                  autoWidth
                  onChange={handleChange}
                  value={projectFilterValue}
                  MenuProps={
                    {classes: {paper: 'projectList'},
                    }}
                >
                  <DropdownBlob className="dropDownBlob"/>
                  <MenuItem key={0} value={''}>All Projects</MenuItem>
                  {
                    projects && Object.values(projects).map((project) => (
                      <MenuItem autoFocus={false} key={project.id} value={project.id}>{project.name}</MenuItem>
                    ))
                  }

                </Select>
              </FormControl>
            </Grid>
            <Grid item className={classes.itemStart}>
              {
                projectFilterValue &&
                  <FormControl fullWidth>
                    <Select
                      IconComponent={CustomarrowDown}
                      labelId="mutiple-checkbox-label"
                      id="epics-multiple-checkbox"
                      multiple
                      value={epicFilterValues}
                      onChange={handleMultiChange}
                      input={<Input />}
                      renderValue={getSelectValues}
                      displayEmpty
                      disableUnderline
                      MenuProps={
                        {classes: {paper: 'epicsList'}}
                      }
                    >
                      <DropdownBlob className="dropDownBlob"/>
                      <MenuItem
                        value="all"
                        classes={{root: isAllSelected ? 'Mui-selected' : ''}}
                      >
                        <Checkbox
                          checked={isAllSelected}
                          indeterminate={
                            epicFilterValues.length > 0 && epicFilterValues.length < filteredEpics.length
                          }
                        />
                        <ListItemText
                          primary="All Epics"
                        />
                      </MenuItem>
                      {filteredEpics.map((filteredEpic) => (
                        <MenuItem key={filteredEpic.id} value={filteredEpic.id}>
                          <Checkbox checked={epicFilterValues.indexOf(filteredEpic.id) > -1} />
                          <ListItemText primary={filteredEpic.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
              }
            </Grid>
          </> :
          null
          }
        </Grid>
        <Grid className="kanbanBoardContainer" container direction="row">
          <Grid item>
            <Board
              renderColumnHeader={renderColumnHeader}
              onCardDragEnd={handleCardMove}
              disableColumnDrag
              allowRemoveCard
              inputProps={{classes: {root: 'dragContainer'}}}
              // eslint-disable-next-line react/no-children-prop
              renderCard={(card) => <BoardCard key={card.id} content={card} ></BoardCard>}
              allowAddCard={{on: 'bottom'}} >
              {controlledBoard}
            </Board>
          </Grid>
        </Grid>
      </div>
      <div>
        { waiting ?
          <Typography align='center'>
            <CircularProgress className={classes.loadIcon}/>
          </Typography> :
          <span></span>
        }
      </div>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
}));

export default BoardOverview;
