import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function addIssue(values, files, epicId, projectId, alert) {
  const issueData = createBodyFormData(values, files, epicId, projectId);
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/issue`, issueData, {
    }).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function editIssue(id, values, files, epicId, projectId, alert) {
  let issueData = null;
  if (epicId === null && projectId === null) {
    issueData = values;
  } else {
    issueData = createBodyFormData(values, files, epicId, projectId);
  }
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/issue/${id}?_method=PUT`, issueData, {
    }).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function getIssueMedia(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/issue/${id}/media`, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function deleteIssue(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.delete(`/issue/${id}`, {
    }).then((res) => {
      resolve(res.data.data);
      alert({
        title: `Issue successfully deleted`,
        severity: 'success',
      });
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

function createBodyFormData(values, files, epicId, projectId) {
  const bodyFormData = new FormData();
  bodyFormData.append('subject', values['subject']);
  if (projectId != null) {
    bodyFormData.append('projectId', projectId);
  }
  bodyFormData.append('epicId', epicId);
  bodyFormData.append('status_id', values['status_id']);
  const description = values['description'];
  delete values['subject'];
  delete values['description'];

  let descriptionString = JSON.stringify(values);
  descriptionString = descriptionString.replaceAll('{', '--Custom vragen-- \n'); // !! important for backend !!
  descriptionString = descriptionString.replaceAll('}', '');
  descriptionString = descriptionString.replaceAll('"', '');
  descriptionString = descriptionString.replaceAll(',', '\n');
  descriptionString = descriptionString.replaceAll(':', ': ');

  bodyFormData.append('description', (description + '\n\n' + descriptionString));
  if (files != null) {
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append('file[]', files[i]);
    }
  }

  return bodyFormData;
}
