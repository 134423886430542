import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function getQuestionaireFields(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/questionaire/${id}/questionaireFields`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function addQuestionaireFields(issueId, values, fields, alert) {
  const answers = {questionaireFields: createFieldsArray(values, fields)};
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/issue/${issueId}/questionaireFields`, answers)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function getIssueQuestionaireFields(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/issue/${id}/questionaireFields`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function editIssueQuestionaireFields(issueId, values, fields, alert) {
  const answers = {questionaireFields: createFieldsArray(values, fields)};
  return new Promise((resolve, reject) => {
    AxiosInstance.put(`issue/${issueId}/questionaireFields`, answers)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

function createFieldsArray(values, fields) {
  const fieldsArray = [];
  const obj = Object.entries(values);
  for (const field of fields) {
    obj.forEach(([key, value]) => {
      if (key == field.name) {
        const answer = {id: field.id, answer: value};
        fieldsArray.push(answer);
      }
    });
  }

  return fieldsArray;
}
