import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function getComments(issueId, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/issue/${issueId}/comments`, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function addComment(data, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/comment`, data, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function editComment(id, data, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/comment/${id}?_method=PUT`, data, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function deleteComment(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.delete(`/comment/${id}`, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function getCommentMedia(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/comment/${id}/media`, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}
