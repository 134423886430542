import React, {useEffect, useState, useContext, useRef} from 'react';
import {makeStyles, Grid, Box, FormControl, TextField, Button, Typography, IconButton, Badge} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import {getComments, addComment, deleteComment, getCommentMedia, editComment} from '../../requests/CommentRequests';
import SendSharpIcon from '@mui/icons-material/SendSharp';
import {withStyles} from '@material-ui/core/styles';
import UserContext from '../../context/UserContext';
import ProfileImageContext from '../../context/ProfileImageContext';
import {useFormik} from 'formik';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFileRounded';
import ConfirmDialog from '../ConfirmDialog';
import {DropzoneDialogBase} from 'material-ui-dropzone';
import InputEmoji from 'react-input-emoji';
import AlertContext from '../../context/AlertContext';
import CircularProgress from '@mui/material/CircularProgress';
import {default as userIcon} from '../../userIcon.svg';

function CommentsSection({issue}) {
  const classes = useStyles();
  const [comments, setComments] = useState([]);
  const userContext = useContext(UserContext);
  const ProfileContext = useContext(ProfileImageContext);
  const lastComment = useRef(null);
  const [key, setKey] = useState(0);
  const [commentToDelete, setCommentToDelete] = useState();
  const [confirmOpen, setConfirmOpen]= useState(false);
  const [openFileDialog, setOpenFileDialog]= useState(false);
  const [files, setFiles] = useState([]);
  const [awsFiles, setAwsFiles] = useState([]);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [commentText, setCommentText] = useState('');
  const {alert} = useContext(AlertContext);
  const [isShown, setIsShown] = useState(false);


  const executeScroll = () => {
    if (lastComment.current != null) {
      lastComment.current.scrollIntoView({behavior: 'smooth'});
    }
  };

  const setClickedComment = (comment) => {
    setConfirmOpen(true);
    setCommentToDelete(comment);
  };

  const removeComment = async (comment) => {
    await deleteComment(comment.id, alert);
    setCommentToDelete();
  };

  useEffect(async () => {
    const comments = await getComments(issue.id, alert);
    setComments(comments);
    setLoading(false);

    // Set comments read
    for (const comment of comments) {
      handleUnreadComment(comment);
    }

    for (const comment of comments) {
      if (comment.author == userContext.user.name && comment.media) {
        setCommentMedia(comment);
      }
    }
  }, []);

  useEffect(async () => {
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.CommentAddedEvent', async (e) => {
      if (e.message != undefined) {
        const addedComment = e.message;
        if (addedComment.issue_id == issue.id) {
          setComments((comments) => comments.concat(addedComment));
          setCommentMedia(addedComment);
        }
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.CommentUpdatedEvent', (e) => {
      if (e.message != undefined) {
        const editedComment = e.message;
        if (editedComment.issue_id == issue.id) {
          setComments((comments) => {
            return comments.map((comment) => {
              return comment.id === editedComment.id ? editedComment : comment;
            });
          });
        }
      }
    });
    await window.Echo.private('board-page.' + userContext.hashedOrganisation).listen('.CommentDeletedEvent', (e) => {
      if (e.message != undefined) {
        const deletedComment = e.message;
        if (deletedComment.issue_id == issue.id) {
          setComments((comments) => {
            return comments.filter((comment) => comment.id !== deletedComment.id);
          });
        }
      }
    });
  }, []);

  const setCommentMedia = async (comment) => {
    const media = await getCommentMedia(comment.id, alert);
    const commentMediaObject = {commentId: comment.id, media: media};
    setAwsFiles((awsFiles) => [...awsFiles, commentMediaObject] );
  };

  const addFile = (file) => {
    setFiles([].concat(files, file));
  };

  const removeFile = (file) => {
    const array = [...files];
    const index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array);
    }
  };

  const formik = useFormik({
    initialValues: {
      comment: '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setWaiting(true);
      if (!commentText.replace(/\s/g, '').length) {
        alert({
          title: `Message cannot be empty`,
          severity: 'warning',
        });
        setWaiting(false);
      } else {
        handleSubmit(commentText);
      }
    },
  });

  const onEnter = (text) => {
    if (!commentText.replace(/\s/g, '').length) {
      alert({
        title: `Message cannot be empty`,
        severity: 'warning',
      });
      setWaiting(false);
    } else {
      handleSubmit(commentText);
    }
  };

  const handleSubmit = async (commentText) => {
    const bodyFormData = new FormData();
    bodyFormData.append('issueId', issue.id);
    bodyFormData.append('description', commentText);
    for (let i = 0; i < files.length; i++) {
      bodyFormData.append('file[]', files[i].file);
    }

    await addComment(bodyFormData, alert);
    setCommentText('');
    setKey(key + 1);
    setFiles([]);
    setWaiting(false);
  };

  const handleUnreadComment = async (comment) => {
    if (comment.comment_read == null) {
      const bodyFormData = new FormData();
      bodyFormData.append('description', comment.description);
      await editComment(comment.id, bodyFormData);
    }
  };

  return (
    <div id="commentContainer" className={classes.flexbox}>
      <Box className={classes.box1 && 'chatOverviewArea'}>
        { comments.length ? Object.values(comments).map((comment, i) => (
          <div key={comment.id} ref={lastComment}>
            { comment.author == userContext.user.name ?
                <Grid container key={comment.id} wrap="nowrap" spacing={2} justifyContent='flex-end'>
                  <Grid item className={classes.commentRight + ' commentTextContainer'}>
                    <span className="commentText">
                      <h4 className={classes.headerColorRight}>{comment.author}</h4>
                      <IconButton size='small' onClick={() => setClickedComment(comment)}>
                        <DeleteIcon fontSize='small'/>
                      </IconButton>
                    </span>
                    <span className="commentText">{comment.description}</span>
                    { awsFiles.length ? Object.values(awsFiles).map((file, i) => (
                      <div className="topFileContainer" key={i}>
                        { file.commentId == comment.id ?
                        <div className="mainFileContainer">
                          <Box className={classes.form} display='flex' justifyContent='flex-end'>
                            {file.media.length ? Object.values(file.media).map((med, i) => (
                              <div key={med.file.id} className={classes.img && 'fileContainer'}>
                                { med.file.extension == 'mp4' || med.file.extension == 'mov' ?
                                  <video autoPlay loop muted width={50} height={40}
                                    onClick={()=> window.open(med.url, med.file.name)}>
                                    <source src={med.url} type="video/mp4"/>
                                    <img src={med.url} />
                                  </video> :
                                  <img width={50} height={40}
                                    key={med.file.url}
                                    src={med.url}
                                    onClick={()=> window.open(med.url, med.file.name)}
                                  />
                                }
                              </div>
                            )) : null}
                          </Box>
                        </div> : null
                        }
                      </div>
                    )) : null}
                  </Grid>
                  <Grid item>
                    <Avatar
                      srcSet={ProfileContext.profileImage || userIcon}
                      sx={{width: 35, height: 35}}
                    />
                  </Grid>
                </Grid> :
              <Grid container key={comment.id} wrap="nowrap" spacing={2} justifyContent='flex-start'>
                <Grid item>
                  <Avatar
                    srcSet={userIcon}
                    sx={{width: 35, height: 35}}
                  />
                </Grid>
                <Grid item className={classes.commentLeft + ' commentTextContainer'}>
                  <span className="commentTextLeft">
                    <h4 className={classes.headerColorLeft}>{comment.author}</h4>
                  </span>
                  <span className="commentTextLeft">{comment.description}</span>
                </Grid>
              </Grid>
            }
            { commentToDelete && confirmOpen ?
              <ConfirmDialog
                title="Are u sure u want to delete this comment?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={() => removeComment(commentToDelete)}
              ></ConfirmDialog> : <span></span>
            }
          </div>
        )) : <span></span>}
        <div>
          { loading ?
            <Typography align='center'>
              <CircularProgress className={classes.loadIcon}/>
            </Typography> :
            <span></span>
          }
        </div>
      </Box>
      {executeScroll()}
      <Box className={classes.box2 && 'answerContainer'}>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <Grid container direction="row" justifyContent='space-between'>
            <Grid item xs={10}>
              <FormControl className={classes.width} key={key}>
                <InputEmoji
                  id="comment"
                  name='comment'
                  cleanOnEnter
                  placeholder="Send a message"
                  required
                  maxLength= '255'
                  onEnter={onEnter}
                  onChange={setCommentText}
                  value={commentText}
                />

              </FormControl>
              <Grid item xs={2} sm={1} onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                <Badge badgeContent={files.length} color="primary" anchorOrigin={{vertical: 'top', horizontal: 'left'}} variant={!isShown && ('dot')} overlap="circular">
                  <IconButton className={classes.AttachFileIcon} size="small" onClick={() => setOpenFileDialog(true)}>
                    <AttachFileIcon fontSize="small"/>
                  </IconButton>
                </Badge>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography align='center'>
                <StyledButton type="submit" className="button-block" size="small" disabled={waiting}>
                  <SendSharpIcon fontSize="small"/>
                </StyledButton>
              </Typography>
            </Grid>
          </Grid>
        </form>
        <DropzoneDialogBase
          dialogTitle={''}
          acceptedFiles={['.jpg', '.mp4', '.png', '.jpeg', '.mov']}
          fileObjects={files}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          maxFileSize={8000000}
          filesLimit={3}
          open={openFileDialog}
          onAdd={(newFile) => {
            addFile(newFile);
          }}
          onDelete={(deleteFile) => {
            removeFile(deleteFile);
          }}
          onClose={() => setOpenFileDialog(false)}
          onSave={() => {
            setOpenFileDialog(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
          alertSnackbarProps={{anchorOrigin: {vertical: 'top', horizontal: 'center'},
            classes: {root: 'alertContainer'}}}
        />
      </Box>
    </div>
  );
};


const useStyles = makeStyles((theme) => ({
  width: {
    width: '100%',
  },
  box1: {
    width: '100%',
    position: 'relative',
    height: '92%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  box2: {
    width: '100%',
    position: 'relative',
    height: '8%',
  },
  flexbox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  form: {
    width: '100%',
    marginTop: '7px',
  },
  commentLeft: {
    width: '60%',
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '10px',
    backgroundColor: '#F0FFFF',
    borderRadius: '10px',
  },
  commentRight: {
    width: '60%',
    marginTop: '10px',
    marginBottom: '10px',
    marginLeft: '10px',
    backgroundColor: '#F0FFF0',
    borderRadius: '10px',
  },
  alignLeft: {
    margin: 0,
    float: 'left',
  },
  alignRight: {
    margin: 0,
    float: 'right',
  },
  headerColorLeft: {
    margin: 0,
    textAlign: 'left',
    float: 'left',
    color: '#87CEEB',
  },
  headerColorRight: {
    margin: 0,
    textAlign: 'right',
    float: 'right',
    color: '#68BB59',
  },
  AttachFileIcon: {
    'backgroundColor': 'white',
    'border': '1px solid rgb(0,0,0,0.23)',
  },
  img: {
    marginLeft: '10px',
  },
}));

// eslint-disable-next-line no-unused-vars
const StyledTextField = withStyles({
  root: {
    'backgroundColor': 'white',
    'borderRadius': '80px',
    'fontFamily': 'Quicksand',
    'width': '100%',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        'borderRadius': '80px',
      },
    },
    '& .MuiInputBase-input': {
      'fontFamily': 'Quicksand',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      'borderRadius': '80px',
    },
  },
})(TextField);

const StyledButton = withStyles({
  root: {
    'backgroundColor': 'white',
    'borderRadius': '80px',
    'width': '100%',
    'padding': '9px',
    'border': '1px solid rgb(0,0,0,0.23)',
  },
})(Button);

export default CommentsSection;
