import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function userLogin(email, password, alert, setWaiting) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/login`, {email, password})
        .then((res) => {
          resolve(res.data.data);
          setWaiting(false);
          alert({
            title: `Welcome ${res.data.data.user.name}`,
            severity: 'success',
          });
        }).catch((error) => {
          errorHandler(error, alert);
          setWaiting(false);
          console.log(error);
        });
  });
}

export function loginByToken(token, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/loginWithToken`, {access_token: token}, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      localStorage.removeItem('user_token');
      window.location.reload(false);
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function logout(token) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/logout`, {access_token: token}, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  });
}
