import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import '../css/GlobalStyle.css';

const ConfirmDialog = (props) => {
  const {title, description, open, setOpen, onConfirm} = props;

  return (
    <StyledDialog
      className="confirmationContainer"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog"><div className="deleteIconContainer"><DeleteForeverRoundedIcon className="deleteIcon" fontSize="large"/></div><StyledTypography>{title}</StyledTypography></DialogTitle>
      <DialogContent className="dialogContentContainer">{description}</DialogContent>
      <DialogActions className="dialogActionsContainer">
        <StyledButton
          className="btn_no"
          variant="contained"
          onClick={() => setOpen(false)}
          color="secondary"
        >
          No
        </StyledButton>
        <StyledButton
          className="btn_yes"
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          color="primary"
        >
          Yes
        </StyledButton>
      </DialogActions>
    </StyledDialog>
  );
};

const StyledButton = withStyles({
  root: {
    'borderRadius': '90px',
    'width': '20%',
  },
})(Button);

const StyledDialog = withStyles({
  root: {
    '& .MuiPaper-rounded': {
      'borderRadius': '10px',
    },
  },
})(Dialog);

const StyledTypography = withStyles({
  root: {
    'fontSize': '1.5rem',
    'fontFamily': 'Quicksand',
  },
})(Typography);


export default ConfirmDialog;
