import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography, TextField} from '@material-ui/core';
import UserContext from '../context/UserContext';
import {useFormik} from 'formik';
import {withStyles} from '@material-ui/core/styles';
import {colors} from '../enumerations/Colors.ts';
import {userLogin, loginByToken} from '../requests/AuthRequests';
import CircularProgress from '@mui/material/CircularProgress';
import AxiosInstance from '../AxiosInstance';
import AlertContext from '../context/AlertContext';
import 'animate.css';
import '../css/login.scss';
import '../css/GlobalStyle.css';


function Login() {
  const userContext = useContext(UserContext);
  const {alert} = useContext(AlertContext);
  const [autologinInProgess, setAutologinInProgess] = useState(false);
  const [waiting, setWaiting] = useState(false);

  const handleSubmit = async (values) => {
    const {user, accessToken} = await userLogin(values.email, values.password, alert, setWaiting);
    AxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    userContext.saveToken(accessToken);
    userContext.setUser(() => {
      userContext.hashOrganisation(user.organisation);
      return user;
    });
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      email: '',
    },
    validateOnChange: false,
    onSubmit: (values) => {
      setWaiting(true);
      handleSubmit(values);
    },
  });

  useEffect(async () => {
    const token = userContext.getToken();
    if (token) {
      setAutologinInProgess(true);
      loginByToken(token, alert).then(({user}) => {
        userContext.setUser(() => {
          userContext.hashOrganisation(user.organisation);
          return user;
        });
      }).finally(() => {
        setAutologinInProgess(false);
      });
    }
  }, []);

  if (autologinInProgess) {
    return null;
  }

  return (
    <div id="loginPage">
      <Grid container spacing={0} justifyContent="center" direction="row">
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            className="login-form">
            <Grid item>
              <img src="/assets/Logo-Oddessey.png" className="logo" />
            </Grid>
            <Grid item>
              <StyledTypography variant="h4" id="loginHeader" align='center'>Feedback portal</StyledTypography>
            </Grid>
            <Grid item>
              <form onSubmit={formik.handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <StyledTextField style={{marginTop: '40px'}}
                      type="email"
                      placeholder="Email.."
                      fullWidth
                      name="email"
                      variant="outlined"
                      autoFocus
                      required
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      type="password"
                      placeholder="Password.."
                      fullWidth
                      name="password"
                      variant="outlined"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    <StyledTypography align='right' style={{marginBottom: '30px'}}>
                      <a href='/' style={{textDecoration: 'none', fontSize: '14px'}}
                      >
                        Forgot password?
                      </a>
                    </StyledTypography>
                  </Grid>
                  <Grid item>
                    { waiting ?
                    <Typography align='center'>
                      <CircularProgress/>
                    </Typography> :
                    <Typography align='center'>
                      <StyledButton
                        type="submit"
                        className="button-block">
                          Login
                      </StyledButton>
                    </Typography>
                    }
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const StyledTextField = withStyles({
  root: {
    'backgroundColor': '#F2F0EE',
    'borderRadius': '90px',
    'marginTop': '10px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '90px',
      },
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Quicksand',
    },
  },
})(TextField);

const StyledTypography = withStyles({
  root: {
    color: colors.blue,
    fontFamily: 'Quicksand',
  },
})(Typography);

const StyledButton = withStyles({
  root: {
    backgroundColor: '#F2F0EE',
    borderRadius: '90px',
    width: '50%',
    padding: '10px',
  },
})(Button);

export default Login;
