import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../context/UserContext';
import {makeStyles, Button, Grid, Box, Badge, Icon} from '@material-ui/core';
import {Card, CardHeader, CardActions, CardContent, Typography, Divider, Stepper, Step, StepLabel} from '@mui/material';
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import {withStyles} from '@material-ui/core/styles';
import {getOrganisationProjects, getEpicValidationAlert} from '../../requests/OrganisationRequests';
import {getProjectEpics} from '../../requests/ProjectRequests';
import {useTheme} from '@mui/material/styles';
import {styled} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useNavigate} from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ProjectsContext from '../../context/ProjectsContext';
import CircularProgress from '@mui/material/CircularProgress';
import {default as notifIcon} from '../../notifIcon.svg';
import {ReactComponent as ProjectMessageIcon} from '../../ic_messageProject.svg';
import {ReactComponent as ActiveStepIcon} from '../../active_stepIcon.svg';
import {ReactComponent as DefaultStepIcon} from '../../completed_stepIcon.svg';
import {ReactComponent as FinalCompleteStepIcon} from '../../finalcomplete_stepIcon.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AlertContext from '../../context/AlertContext';
import '../../css/GlobalStyle.css';
import '../../css/app.scss';

function ProjectOverview() {
  const userContext = useContext(UserContext);
  const projectsContext = useContext(ProjectsContext);
  const [epics, setEpics] = useState([]);
  const [archivedEpics, setArchivedEpics] = useState([]);
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const [waiting, setWaiting] = useState(true);
  const [validationAlert, setValidationAlert] = useState('');
  const {alert} = useContext(AlertContext);
  const classes = useStyles();

  useEffect(async () => {
    setEpics([]);
    if (userContext.user.organisation != null) {
      const projectsData = await getOrganisationProjects(userContext.user.organisation, alert);
      if (projectsData != null) {
        projectsContext.setProjects(projectsData);

        for (const project of projectsData) {
          const projectInfo = await getProjectEpics(project.id, alert);
          const archivedEpicData = projectInfo.projectArchivedEpics;
          const unArchivedEpicData = projectInfo.projectUnArchivedEpics;

          for (const unarchivedEpic of unArchivedEpicData) {
            setEpics((epics) => epics.concat(unarchivedEpic));
          }

          for (const archivedEpic of archivedEpicData) {
            setArchivedEpics((archivedEpics) => archivedEpics.concat(archivedEpic));
          }
        }
      }
    }

    setWaiting(false);
    const elements = document.querySelectorAll('.MuiTypography-root > strong');
    const parentElements = document.querySelectorAll('.MuiTypography-root.MuiTypography-h5.MuiCardHeader-title');
    for (let i = 0; i < elements.length; i++) {
      if (elements[i].offsetWidth > parentElements[i].offsetWidth) {
        const elWidth = elements[i].offsetWidth - parentElements[i].offsetWidth + 5 + 'px';
        elements[i].style.setProperty('--element-width', elWidth);
        elements[i].style.setProperty('--element-width-num', (elements[i].offsetWidth - parentElements[i].offsetWidth) / 100 * 10 + 's');
        elements[i].classList.add('hoverEffect');
      }
    }

    const validation = await getEpicValidationAlert(userContext.user.organisation, alert);
    setValidationAlert(validation);
  }, []);

  useEffect(async () => {
    // Project broadcast events
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.ProjectUpdatedEvent', async (e) => {
      if (e.message != undefined) {
        const editedProject = e.message;
        projectsContext.setProjects((projects) => {
          return projects.map((project) => {
            return project.id === editedProject.id ? editedProject : project;
          });
        });
        // Update project names from epics associated with updated Project.
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.project_id === editedProject.id ? {...epic, project: editedProject} : epic;
          });
        });
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.ProjectDeletedEvent', async (e) => {
      if (e.message != undefined) {
        const deletedProject = e.message;
        projectsContext.setProjects((projects) => {
          return projects.filter((project) => project.id !== deletedProject.id);
        });
        // Remove all epics associated with deleted Project.
        setEpics((epics) => {
          return epics.filter((epic) => epic.project_id !== deletedProject.id);
        });
      }
    });
    // Epic broadcast events
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.EpicAddedEvent', async (e) => {
      if (e.message != undefined) {
        const addedEpic = e.message;
        setEpics((epics) => epics.concat(addedEpic));
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.EpicUpdatedEvent', async (e) => {
      if (e.message != undefined) {
        const editedEpic = e.message;
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.id === editedEpic.id ? editedEpic : epic;
          });
        });
        setArchivedEpics((archivedEpics) => {
          return archivedEpics.map((archivedEpic) => {
            return archivedEpic.id === editedEpic.id ? editedEpic : archivedEpic;
          });
        });
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.EpicDeletedEvent', (e) => {
      if (e.message != undefined) {
        const deletedEpic = e.message;
        setEpics((epics) => {
          return epics.filter((epic) => epic.id !== deletedEpic.id);
        });
        setArchivedEpics((archivedEpics) => {
          return archivedEpics.filter((archivedEpic) => archivedEpic.id !== deletedEpic.id);
        });
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.EpicArchivedEvent', async (e) => {
      if (e.message != undefined) {
        const archivedEpic = e.message;
        setEpics((epics) => {
          return epics.filter((epic) => epic.id !== archivedEpic.id);
        });
        setArchivedEpics((archivedEpics) => archivedEpics.concat(archivedEpic));
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.EpicUnArchivedEvent', async (e) => {
      if (e.message != undefined) {
        const unArchivedEpic = e.message;
        setArchivedEpics((archivedEpics) => {
          return archivedEpics.filter((archivedEpic) => archivedEpic.id !== unArchivedEpic.id);
        });
        setEpics((epics) => epics.concat(unArchivedEpic));
      }
    });
    // Issue broadcast events
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.IssueUpdatedEvent', async (e) => {
      if (e.message != undefined) {
        const editedIssue = e.message;
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.id === editedIssue.epic_id ? {...epic, status: editedIssue.epic.status} : epic;
          });
        });
      }
    });
    await window.Echo.private('overview-page.' + userContext.hashedOrganisation).listen('.IssueDeletedEvent', async (e) => {
      if (e.message != undefined) {
        const deletedIssue = e.message;
        setEpics((epics) => {
          return epics.map((epic) => {
            return epic.id === deletedIssue.epic_id ? {...epic, status: deletedIssue.epic.status} : epic;
          });
        });
      }
    });
  }, []);

  const handleBoard = (epic) => {
    navigate('/board', {state: {epicProjectId: epic.project_id, epicId: epic.id}});
  };

  const CustomStepIconRoot = styled('div')(({theme, ownerState}) => ({

    ...(ownerState.active && {

    }),
    ...(ownerState.completed && {

    }),
  }));

  const ActiveStateStepIcon = (props) => {
    return (
      <div>
        <ActiveStepIcon className='active_Icon'/>
      </div>
    );
  };

  const LastStepIcon = (props) => {
    return (
      <div>
        <FinalCompleteStepIcon className='complete_Icon'/>
      </div>
    );
  };

  const CustomStepIcon = (props) => {
    const {active, completed, className} = props;

    return (
      <CustomStepIconRoot
        ownerState={{active, completed}} className={className}
      >
        {completed ? (
           <DefaultStepIcon className='color_default_Icon' />
        ) : (
          <DefaultStepIcon className='default_Icon'/>
        )}

        {/* {stepIcons[String(props.icon)]} */}
      </CustomStepIconRoot>
    );
  };

  CustomStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  function navigateBottom() {
    const archiveView = document.querySelector('#archiveContentContainer');
    archiveView.scrollTop = archiveView.scrollHeight - archiveView.clientHeight;
  }

  return (
    <div>
      <Box>
        <div>
          <Grid id="notifBar" container direction="row" spacing={2}>
            <Grid item xs={12} sm={7}>
              {validationAlert &&
              <Card >
                <CardHeader
                  avatar={
                    <Icon>
                      <img src={notifIcon} />
                    </Icon>
                  }
                  title={<Typography>Validation alert</Typography>}
                  subheader={<Typography>{validationAlert.name}</Typography>}
                  action={
                    <Box>
                      {isMobile ? (
                        <AlertButton onClick={() => handleBoard(validationAlert)}>{'>'}</AlertButton>
                      ) : (
                        <AlertButton onClick={() => handleBoard(validationAlert)}>View Task {'>'}</AlertButton>
                      )}

                    </Box>
                  }
                >
                </CardHeader>
              </Card>
              }
            </Grid>
            {!matches &&
            <Grid item sm={5}>

            </Grid>
            }
          </Grid>

          <Grid id="project" container direction="row" spacing={2}>
            {epics && Object.values(epics).map((epic) => (
              <Grid item xs={12} sm={6} md={6} lg={4} style={{'--prime-color': epic.color.primary_color, '--second-color': epic.color.secondary_color, '--opac-color-33': epic.color.secondary_color + '33', '--opac-color-50': epic.color.secondary_color + '50', '--opac-color-77': epic.color.secondary_color + '77'}} key={epic.id}>
                <Card>
                  <div>
                    {epic.project.jira_icon != null &&
                    <img src={epic.project.jira_icon} className={classes.projectIcon}/>
                    }
                  </div>
                  <CardHeader
                    title={<strong>{epic.name}</strong>}
                    subheader={<Typography sx={{color: epic.color.secondary_color}}>{epic.project.name}</Typography>}
                  >
                  </CardHeader>
                  <CardContent>

                    <Typography>
                      Process
                    </Typography>


                    <Stepper activeStep={epic.status} alternativeLabel>
                      <Step>
                        <StepLabel StepIconComponent={ epic.status <= 1 ? ActiveStateStepIcon : epic.status > 1 && CustomStepIcon}>
                          {epic.status <=1 &&
                          <Typography>
                            {epic.status == 1 &&
                                <span>To Do</span>
                            }
                            {epic.status <= 0 &&
                                <span>Backlog</span>
                            }
                          </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={epic.status == 2 ? ActiveStateStepIcon : epic.status < 2 ? CustomStepIcon : epic.status > 2 && CustomStepIcon}>
                          {epic.status == 2 &&
                          <Typography>
                            <span>In Progress</span>
                          </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={epic.status == 3 ? ActiveStateStepIcon : epic.status < 3 ? CustomStepIcon : epic.status > 3 && CustomStepIcon}>
                          {epic.status == 3 &&
                          <Typography>
                            <span>Validate</span>
                          </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={epic.status >= 4 ? LastStepIcon : epic.status < 4 && CustomStepIcon}>
                        </StepLabel>
                      </Step>
                    </Stepper>

                  </CardContent>
                  <Divider variant="middle" />
                  <CardActions>
                    <Grid container direction="row" spacing={2} >
                      <Grid item xs={2}>

                      </Grid>
                      <Grid item xs={10}>
                        <StyledButton className="button-block" onClick={() => handleBoard(epic)} >View Board</StyledButton>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            ))}
            {waiting ?
          <Typography align='center'>
            <CircularProgress className={classes.loadIcon}/>
          </Typography> :
          <span></span>
            }
          </Grid>
        </div>
      </Box>

      {archivedEpics.length ?
      <Box id="archiveContainer">
        <div>
          <Accordion id="accordionContainer">
            <AccordionSummary
              id="accordionButtonContainer"
              onClick={navigateBottom}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content">
              <Typography id="archivedTitle" className="archivedTitle">
                  Archived Epics
              </Typography>
            </AccordionSummary>
            <AccordionDetails id="archiveContentContainer">
              <Grid id="project" container direction="row" spacing={2}>
                {archivedEpics && Object.values(archivedEpics).map((epic) => (
                  <Grid item xs={12} sm={4} style={{'--prime-color': epic.color.primary_color, '--second-color': epic.color.secondary_color, '--opac-color-33': epic.color.secondary_color + '33', '--opac-color-50': epic.color.secondary_color + '50', '--opac-color-77': epic.color.secondary_color + '77'}} key={epic.id}>
                    <Card>
                      <div>
                        {epic.project.jira_icon != null &&
                    <img src={epic.project.jira_icon} className={classes.projectIcon}/>
                        }
                      </div>
                      <CardHeader
                        title={<strong>{epic.name}</strong>}
                        subheader={<Typography sx={{color: epic.color.secondary_color}}>{epic.project.name}</Typography>}
                      >
                      </CardHeader>
                      <CardContent>
                        {!matches &&
                    <Typography>
                            Process
                    </Typography>
                        }
                        {!matches &&
                    <Stepper activeStep={epic.status} alternativeLabel>
                      <Step>
                        <StepLabel StepIconComponent={ epic.status <= 1 ? ActiveStateStepIcon : epic.status > 1 && CustomStepIcon}>
                          {epic.status <=1 &&
                            <Typography>
                              {epic.status == 1 &&
                                  <span>ToDo</span>
                              }
                              {epic.status <= 0 &&
                                  <span>Backlog</span>
                              }
                            </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={CustomStepIcon}>
                          {epic.status == 2 &&
                            <Typography>
                              <span>InProgress</span>
                            </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={CustomStepIcon}>
                          {epic.status == 3 &&
                            <Typography>
                              <span>Validate</span>
                            </Typography>
                          }
                        </StepLabel>
                      </Step>
                      <Step>
                        <StepLabel StepIconComponent={epic.status >= 4 ? LastStepIcon : epic.status < 4 && CustomStepIcon}>
                          {epic.status >= 4 &&
                            <Typography>
                              <span>Done</span>
                            </Typography>
                          }
                        </StepLabel>
                      </Step>
                    </Stepper>
                        }
                      </CardContent>
                      <Divider variant="middle" />
                      <CardActions>
                        <Grid container direction="row" spacing={2} >
                          <Grid item xs={2}>
                            <Badge badgeContent={0} color="error">
                              <ProjectMessageIcon
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                              >
                                <TextsmsOutlinedIcon/>
                              </ProjectMessageIcon>
                            </Badge>
                          </Grid>
                          <Grid item xs={10}>
                            <StyledButton className="button-block" onClick={() => handleBoard(epic)} >View Board</StyledButton>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
                {waiting ?
            <Typography align='center'>
              <CircularProgress className={classes.loadIcon}/>
            </Typography> :
            <span></span>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </div>
      </Box> :
      <div></div>
      }
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  loadIcon: {
    margin: '160px',
    width: '50px',
    heigh: '50px',
  },
}));

const StyledButton = withStyles({
})(Button);

const AlertButton = withStyles({

})(Button);

export default ProjectOverview;
