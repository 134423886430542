import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function getStatuses(alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/statuses`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}
