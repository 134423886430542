import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function editUser(userData, id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.post(`/user/${id}?_method=PUT`, userData, {
    }).then((res) => {
      resolve(res.data.data);
      alert({
        title: `Changes saved`,
        severity: 'success',
      });
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}

export function getUser(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/user/${id}/image`, {
    }).then((res) => {
      resolve(res.data.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}
