import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function deleteMedia(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.delete(`/media/${id}`, {
    }).then((res) => {
      resolve(res.data);
    }).catch((error) => {
      errorHandler(error, alert);
      console.log(error);
    });
  });
}
