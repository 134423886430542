import React, {useState} from 'react';

const ProjectQuestionairesContext = React.createContext();

export function ProjectQuestionairesProvider(props) {
  const [questionaires, setQuestionaires] = useState([]);

  return (
    <ProjectQuestionairesContext.Provider value={{
      questionaires,
      setQuestionaires: setQuestionaires,
    }}>
      {props.children}
    </ProjectQuestionairesContext.Provider>
  );
}

export default ProjectQuestionairesContext;
