import React, {useContext, useState, useRef, useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import {AppBar, Grid, Toolbar, IconButton, Button, Box, MenuList, MenuItem, Typography, Popper, Grow, Paper, List, ListItem} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import UserContext from '../context/UserContext';
import ProfileImageContext from '../context/ProfileImageContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ProfileModal from './ProfilePage/ProfileOverview';
import {getUser} from '../requests/UserRequests';
import {default as userIcon} from '../userIcon.svg';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import {ReactComponent as DropdownBlob} from '../dropdownBlob.svg';
import AlertContext from '../context/AlertContext';
import '../css/GlobalStyle.css';
import '../css/app.scss';

function Navbar() {
  const userContext = useContext(UserContext);
  const ProfileContext = useContext(ProfileImageContext);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const {alert} = useContext(AlertContext);

  const handleModalOpen = () =>{
    setModal(true);
    setOpen(false);
  };

  const handleModalClose = () =>{
    setModal(false);
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleLogout = () => {
    userContext.logOut();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);
  useEffect(async () => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(async () => {
    if (userContext.user != null) {
      const image = await getUser(userContext.user.id, alert);
      ProfileContext.setProfileImage(image);
    }
  }, []);

  return (
    <div id="navBar">
      <AppBar>
        <Toolbar >

          {isMobile ? (
            <Grid className="mobileContainer" container direction="row" spacing={2}>

              <Button className="hamburgerContainer" onClick={handleDrawerToggle}><MenuRoundedIcon/></Button>
              <Drawer
                open={mobileOpen}
                onClose={handleDrawerToggle}
                PaperProps={
                  {className: 'mobileDrawerContainer'}
                }
              >

                <Box className=""
                  sx={{width: 250}}
                  role="presentation"
                  onClick={handleDrawerToggle}
                  onKeyDown={handleDrawerToggle}
                >
                  <List className="mobileDrawerUserContainer">
                    <IconButton>
                      <Avatar
                        srcSet={ProfileContext.profileImage || userIcon}
                        sx={{width: 50, height: 50}}
                      />
                    </IconButton>
                    <Grid container direction="column" justifyContent="center">
                      <Grid item>
                        <Typography className="userName">
                          {userContext.user.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography>
                          {userContext.user.email}
                        </Typography>
                      </Grid>
                    </Grid>


                  </List>
                  <Divider />
                  <List className="mobileNavListContainer">
                    <ListItem className="mobileNavItemsContainer" key={0} disablePadding>
                      <NavLink to="/" className={({isActive}) => (isActive ? 'active_link button-block' : 'button-block')}>Overview</NavLink>
                    </ListItem>
                    <ListItem className="mobileNavItemsContainer">
                      <NavLink to="/board" className={({isActive}) => (isActive ? 'active_link button-block' : 'button-block')}>Board</NavLink>
                    </ListItem>
                  </List>
                  <Divider />
                  <List className="mobileExtraNavItemsContainer">
                    <MenuItem onClick={handleModalOpen}>Profile</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </List>
                </Box>

              </Drawer>

              <Grid className="mobileOddesseyContainer" item>
                <Box
                  className="mobileOddesseyLogo"
                  component="img"
                  alt="Oddessey solutions"
                  src="/assets/Beeldmerk-Oddessey.png"
                />
              </Grid>
              {!matches &&
                <Grid className="mobileUserContainer" container direction="column" justifyContent="center">
                  <Grid item>
                    <Typography>
                      {userContext.user.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {userContext.user.email}
                    </Typography>
                  </Grid>
                </Grid>
              }
              <IconButton className="mobileAvatarContainer">
                <Avatar
                  className="mobileAvatar"
                  srcSet={ProfileContext.profileImage || userIcon}
                  sx={{width: 50, height: 50}}
                />
              </IconButton>
            </Grid>
          ) : (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={3} sm={1}>
              <Box
                component="img"
                alt="Oddessey solutions"
                src="/assets/Beeldmerk-Oddessey.png"
              />
            </Grid>

            <Grid item xs={6} sm={9}>
              <NavLink to="/" className={({isActive}) => (isActive ? 'active_link button-block' : 'button-block')}>Overview</NavLink>
              <NavLink to="/board" className={({isActive}) => (isActive ? 'active_link button-block' : 'button-block')}>Board</NavLink>
            </Grid>


            <Grid item xs={3} sm={2}>
              {!matches &&
                <Grid container direction="column" justifyContent="center">
                  <Grid item>
                    <Typography>
                      {userContext.user.name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {userContext.user.email}
                    </Typography>
                  </Grid>
                </Grid>
              }
              <IconButton>
                <Avatar
                  srcSet={ProfileContext.profileImage || userIcon}
                  sx={{width: 50, height: 50}}
                />
              </IconButton>
              <IconButton
                ref={anchorRef}
                id="composition-icon"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
              >
                <MoreVertIcon fontSize="large"/>
              </IconButton>
              <Popper
                id="menuDropdown"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
              >
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-icon"
                        >
                          <DropdownBlob className="dropDownBlob"/>
                          <MenuItem onClick={handleModalOpen}>Profile</MenuItem>
                          <MenuItem onClick={handleLogout}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
            )}

        </Toolbar>
      </AppBar>
      <ProfileModal visible={modal} onCancel={handleModalClose}/>
    </div>
  );
}

export default Navbar;
