import React, {useState} from 'react';
import {Grid, Box} from '@material-ui/core';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import PropTypes from 'prop-types';
import IssueModal from '../IssuePage/CreateIssue';
import {ReactComponent as IconButton} from '../../plusIcon.svg';

function BoardColumnHeader({id, title, projectFilterValue, epicFilterValues}) {
  const [modal, setModal] = useState(false);

  const handleModalOpen = () =>{
    setModal(true);
  };

  const handleModalClose = () =>{
    setModal(false);
  };

  return (
    <Box pb={2}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={7}>
          {title}
        </Grid>
        <Grid item sm={5}>
          {
            id == 1 ?
            <IconButton onClick={handleModalOpen}
              id="composition-icon"
            >
              <AddCircleOutlineOutlinedIcon fontSize="small"/>
            </IconButton> :
            <div></div>
          }
          <IssueModal visible={modal} onCancel={handleModalClose} projectFilterValue={projectFilterValue} epicFilterValues={epicFilterValues}/>
        </Grid>
      </Grid>
    </Box>
  );
}

BoardColumnHeader.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
};

BoardColumnHeader.defaultProps = {
  id: null,
  title: '',
};

export default BoardColumnHeader;
