import React from 'react';
import Context from './context/Context';
import RouterComponent from './components/RouterComponent';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {colors} from './enumerations/Colors.ts';
import Echo from 'laravel-echo';
import AxiosInstance from './AxiosInstance';
// eslint-disable-next-line no-unused-vars
import Pusher from 'pusher-js';

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Quicksand',
    },
  },
});

function App() {
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    enableStats: false,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          AxiosInstance.post(process.env.REACT_APP_PUSHER_HOST_URL + '/api/broadcasting/auth',
              {socket_id: socketId, channel_name: channel.name}, {
              }).then((response) => {
            callback(false, response.data);
          }).catch((error) => {
            callback(true, error);
          });
        },
      };
    },
  });

  document.body.style.backgroundColor = colors.grey;

  return (
    <ThemeProvider theme={theme}>
      <Context>
        <RouterComponent/>
      </Context>
    </ThemeProvider>
  );
}

export default App;
