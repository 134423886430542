import React, {useState} from 'react';

const ProfileImageContext = React.createContext();

export function ProfileImageProvider(props) {
  const [profileImage, setProfileImage] = useState();

  return (
    <ProfileImageContext.Provider value={{
      profileImage,
      setProfileImage: setProfileImage,
    }}>
      {props.children}
    </ProfileImageContext.Provider>
  );
}

export default ProfileImageContext;
