export function errorHandler(error, alert) {
  if (error.response.status == 429) {
    alert({title: 'Too many requests, please wait and try again later', severity: 'error'});
  } else if (error.response.status == 403) {
    alert({title: 'Email or password was incorrect', severity: 'error'});
  } else if (error.response.status == 404) {
    alert({title: 'Data not found', severity: 'error'});
  } else if (error.response.status == 500) {
    alert({title: 'Something went wrong, please try again', severity: 'error'});
  } else {
    alert({title: 'Something went wrong', severity: 'error'});
  }
}
