import React, {useState} from 'react';
import axios from 'axios';
import {logout} from '../requests/AuthRequests';
import Hashids from 'hashids';

const UserContext = React.createContext();

export function UserProvider(props) {
  const [user, setUser] = useState();
  const [hashedOrganisation, setHashedOrganisation] = useState('');

  const saveToken = (accessToken) => {
    localStorage.setItem('user_token', accessToken);
  };

  const logOut = async () => {
    setUser(null);
    const token = localStorage.getItem('user_token');
    localStorage.removeItem('user_token');
    axios.defaults.headers.common['Authorization'] = null;
    await logout(token);
  };

  const hashOrganisation = (organisationId) => {
    const hashids = new Hashids('', 10);
    setHashedOrganisation(hashids.encode(organisationId));
  };

  return (
    <UserContext.Provider value={{
      user,
      hashedOrganisation,
      setUser: setUser,
      hashOrganisation: hashOrganisation,
      saveToken: saveToken,
      logOut: logOut,
      getToken: () => localStorage.getItem('user_token'),
    }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
