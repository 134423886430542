import React, {useState} from 'react';

const ProjectsContext = React.createContext();

export function ProjectsProvider(props) {
  const [projects, setProjects] = useState();

  return (
    <ProjectsContext.Provider value={{
      projects,
      setProjects: setProjects,
    }}>
      {props.children}
    </ProjectsContext.Provider>
  );
}

export default ProjectsContext;
