import React, {useContext} from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {makeStyles, Container} from '@material-ui/core';
import Login from './LoginComponent';
import ResetPassword from './ResetPasswordComponent';
import UserContext from '../context/UserContext';
import Navbar from './Navbar';
import ProjectOverview from './ProjectPage/ProjectOverview';
import BoardOverview from './BoardPage/BoardOverview';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
  },
}));


function RouterComponent() {
  const classes = useStyles();
  const userContext = useContext(UserContext);

  if (!userContext.user) {
    return <Login />;
  }

  if (userContext.user && userContext.user.firstLogin == null) {
    return <ResetPassword />;
  }

  return (
    <Router>
      <div>
        <div className={classes.root}>
          <Container id="viewContainer">
            <Navbar/>
            <main id="mainContainer" className={classes.content}>
              <Routes>
                <Route exact path="/" element={<ProjectOverview />}/>
              </Routes>
              <Routes>
                <Route exact path="/board" element={<BoardOverview />}/>
              </Routes>
            </main>
          </Container>
        </div>
      </div>
    </Router>
  );
}

export default RouterComponent;
