import React from 'react';
import {UserProvider} from './UserContext';
import {AlertProvider} from './AlertContext';
import {ProjectsProvider} from './ProjectsContext';
import {EpicsProvider} from './EpicsContext';
import {ProjectQuestionairesProvider} from './ProjectQuestionairesContext';
import {ProfileImageProvider} from './ProfileImageContext';

function Context(props) {
  return (
    <AlertProvider>
      <UserProvider>
        <ProjectsProvider>
          <EpicsProvider>
            <ProjectQuestionairesProvider>
              <ProfileImageProvider>
                {props.children}
              </ProfileImageProvider>
            </ProjectQuestionairesProvider>
          </EpicsProvider>
        </ProjectsProvider>
      </UserProvider>
    </AlertProvider>
  );
}

export default Context;
