import React, {useState} from 'react';
import {Card, CardHeader, CardActions, Typography, Chip, Badge, IconButton} from '@mui/material';
import IssueDetail from '../IssuePage/IssueDetail';
import {ReactComponent as AccountCircle} from '../../userIcon.svg';
import {ReactComponent as TextsmsOutlinedIcon} from '../../ic_messageProject.svg';

export const BoardCard = ({content, dragging}) => {
  const [modal, setModal] = useState(false);
  const [isShown, setIsShown] = useState(false);

  const handleModalOpen = () =>{
    setModal(true);
  };

  const handleModalClose = () =>{
    setModal(false);
  };

  return (
    <Card id="card" className="cards" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
      <div onClick={handleModalOpen}>
        <Chip label={content.epic.name} size="small" sx={{marginLeft: '15px', marginTop: '15px', overflow: 'hidden', maxWidth: '85% !important', border: '1px solid', backgroundColor: content.epic.color.primary_color, color: content.epic.color.secondary_color}}/>
        <CardHeader
          title={<Typography>{content.subject}</Typography>}
          subheader={<Typography>{content.description.length > 100 ?
            `${content.description.substring(0, 100)}...` : content.description
          }</Typography>}
        >
        </CardHeader>
        <CardActions>
          <AccountCircle/>
          <Badge badgeContent={content.comments.length} name="badge" color="error" overlap="circular" variant={!isShown && ('dot')}>
            <IconButton
              aria-label="Messages"
              aria-controls="menu-appbar"
              aria-haspopup="true"
            >
              <TextsmsOutlinedIcon />
            </IconButton>
          </Badge>
        </CardActions>
      </div>
      <IssueDetail visible={modal} onCancel={handleModalClose} issue={content}/>
    </Card>
  );
};
