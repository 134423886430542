import AxiosInstance from '../AxiosInstance';
import {errorHandler} from './RequestErrorHandler';

export function getProject(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/project/${id}`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function getProjectEpics(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/project/${id}/epics`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}

export function getProjectQuestionaires(id, alert) {
  return new Promise((resolve, reject) => {
    AxiosInstance.get(`/project/${id}/questionaires`)
        .then((res) => {
          resolve(res.data.data);
        }).catch((error) => {
          errorHandler(error, alert);
          console.log(error);
        });
  });
}
