import React, {useState} from 'react';

const EpicsContext = React.createContext();

export function EpicsProvider(props) {
  const [allEpics, setAllEpics] = useState([]);

  return (
    <EpicsContext.Provider value={{
      allEpics,
      setAllEpics: setAllEpics,
    }}>
      {props.children}
    </EpicsContext.Provider>
  );
}

export default EpicsContext;
