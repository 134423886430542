import React, {useContext, useEffect, useState} from 'react';
import {Button, Grid, Typography, TextField} from '@material-ui/core';
import {useFormik} from 'formik';
import {withStyles} from '@material-ui/core/styles';
import {colors} from '../enumerations/Colors.ts';
import UserContext from '../context/UserContext';
import CircularProgress from '@mui/material/CircularProgress';
import AlertContext from '../context/AlertContext';
import {editUser} from '../requests/UserRequests';
import 'animate.css';
import '../css/login.scss';
import '../css/GlobalStyle.css';

function ResetPassword() {
  const [waiting, setWaiting] = useState(false);
  const {alert} = useContext(AlertContext);
  const userContext = useContext(UserContext);

  const handleSubmit = async (values) => {
    const today = new Date();
    const curTime = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + curTime;

    const bodyFormData = new FormData();
    bodyFormData.append('name', userContext.user.name);
    bodyFormData.append('email', userContext.user.email);
    bodyFormData.append('password', values.confirmpassword);
    if (userContext.user.firstLogin == null) {
      bodyFormData.append('firstLogin', date);
    }

    const response = await editUser(bodyFormData, userContext.user.id, alert);
    const user = {
      id: response.user.id,
      name: response.user.name,
      email: response.user.email,
      organisation: response.user.organisation_id,
      firstLogin: response.user.first_login,
    };

    userContext.setUser(() => {
      userContext.hashOrganisation(user.organisation);
      return user;
    });
    setWaiting(false);
  };

  const formik = useFormik({
    initialValues: {
      newpassword: '',
      confirmpassword: '',
    },
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.newpassword === values.confirmpassword) {
        setWaiting(true);
        handleSubmit(values);
      } else {
        alert({title: 'The passwords u entered do not match, please re-enter the passwords', severity: 'warning'});
      }
    },
  });

  useEffect(async () => {

  }, []);

  return (
    <div>
      <Grid container spacing={0} justifyContent="center" direction="row">
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="center"
            spacing={2}
            className="login-form">
            <Grid item>
              <img src="/assets/Logo-Oddessey.png" className="logo" />
            </Grid>
            <Grid item>
              <StyledTypography variant="h4" id="loginHeader" align='center'>Change password</StyledTypography>
            </Grid>
            <Grid item>
              <form onSubmit={formik.handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <StyledTextField
                      type="password"
                      placeholder="New password"
                      fullWidth
                      name="newpassword"
                      variant="outlined"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.newpassword}
                    />
                  </Grid>
                  <Grid item>
                    <StyledTextField
                      type="password"
                      placeholder="Confirm new password"
                      fullWidth
                      name="confirmpassword"
                      variant="outlined"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.confirmpassword}
                    />
                  </Grid>
                  <Grid item>
                    { waiting ?
                    <Typography align='center'>
                      <CircularProgress/>
                    </Typography> :
                    <Typography align='center'>
                      <StyledButton
                        type="submit"
                        className="button-block">
                          Save
                      </StyledButton>
                    </Typography>
                    }
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

const StyledTextField = withStyles({
  root: {
    'backgroundColor': '#F2F0EE',
    'borderRadius': '90px',
    'marginTop': '10px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '90px',
      },
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Quicksand',
    },
  },
})(TextField);

const StyledTypography = withStyles({
  root: {
    color: colors.blue,
    fontFamily: 'Quicksand',
  },
})(Typography);

const StyledButton = withStyles({
  root: {
    backgroundColor: '#F2F0EE',
    borderRadius: '90px',
    width: '50%',
    padding: '10px',
  },
})(Button);

export default ResetPassword;
