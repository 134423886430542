import React, {useEffect, useContext, useState} from 'react';
import {makeStyles, Box, Modal, Grid, Typography, IconButton, TextField, FormControl, Button, Tooltip} from '@material-ui/core';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import UserContext from '../../context/UserContext';
import ProfileImageContext from '../../context/ProfileImageContext';
import {withStyles} from '@material-ui/core/styles';
import {useFormik} from 'formik';
import {getOrganisation} from '../../requests/OrganisationRequests';
import {editUser} from '../../requests/UserRequests';
import AlertContext from '../../context/AlertContext';
import CircularProgress from '@mui/material/CircularProgress';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import '../../css/GlobalStyle.css';
import {DropzoneDialogBase} from 'material-ui-dropzone';
import {ReactComponent as UserIcon} from '../../userIcon.svg';

function ProfileModal({visible, onCancel}) {
  const classes = useStyles();
  const userContext = useContext(UserContext);
  const ProfileContext = useContext(ProfileImageContext);
  const {alert} = useContext(AlertContext);
  const [organisation, setOrganisation] = useState();
  const [waiting, setWaiting] = useState(false);
  const [openFileDialog, setOpenFileDialog]= useState(false);
  const [files, setFiles] = useState([]);

  useEffect(async () => {
    const org = await getOrganisation(userContext.user.organisation, alert);
    setOrganisation(org['name']);
  }, []);

  const formik = useFormik({
    initialValues: {
      name: userContext.user.name,
      email: userContext.user.email,
      organisation: organisation,
      password: '',
    },
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setWaiting(true);
      handleSubmit(values);
    },
  });

  const addFile = (file) => {
    setFiles(file);
  };

  const removeFile = (file) => {
    const array = [...files];
    const index = array.indexOf(file);
    if (index !== -1) {
      array.splice(index, 1);
      setFiles(array);
    }
  };

  const handleSubmit = async (values) => {
    const bodyFormData = new FormData();
    bodyFormData.append('name', values.name);
    bodyFormData.append('email', values.email);
    if (values.password != '') {
      bodyFormData.append('password', values.password);
    }
    if (files.length > 0) {
      bodyFormData.append('file', files[0].file);
    }

    const response = await editUser(bodyFormData, userContext.user.id, alert);
    const user = {
      id: response.user.id,
      name: response.user.name,
      email: response.user.email,
      organisation: response.user.organisation_id,
      firstLogin: response.user.first_login,
    };
    userContext.setUser(() => {
      userContext.hashOrganisation(user.organisation);
      return user;
    });
    ProfileContext.setProfileImage(response.image);
    formik.values.password = '';
    setFiles([]);
    setWaiting(false);
  };

  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box id="profileContainer" sx={style}>
        <div>
          <Grid container direction="row" justifyContent='space-between'>
            <Typography className="mainTitle">
                Profile
            </Typography>
            <IconButton className="closeButton" onClick={onCancel}>
              <CloseIcon/>
            </IconButton>
          </Grid>
          <Grid container direction="row" justifyContent='flex-start' spacing={2} className="userContainer">
            <Grid item>
              {files.length ?
                <Avatar className="avatarContainer" onClick={() => setOpenFileDialog(true)} style={{cursor: 'pointer'}}
                  alt="Remy Sharp"
                  srcSet={files[0].data}
                  sx={{width: 120, height: 120}}
                /> :
              <Avatar className="avatarContainer" onClick={() => setOpenFileDialog(true)} style={{cursor: 'pointer'}}
                alt="Remy Sharp"
                srcSet={ProfileContext.profileImage}
                sx={{width: 120, height: 120}}
              >
                <UserIcon className="fallbackIcon"></UserIcon>
              </Avatar>
              }
            </Grid>
            <Grid item className="userInfoContainer">
              <Typography className="mainName">
                {userContext.user.name}
              </Typography>
              <Typography className="mainEmail">
                {userContext.user.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent='flex-start' spacing={2} className={classes.padding}>
            <form onSubmit={formik.handleSubmit} className={classes.fullWidth}>
              <FormControl className="formInputs" fullWidth>
                <TextField className={classes.paddingTop}
                  id="name"
                  label="Name"
                  name="name"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  inputProps={{maxLength: 255}}
                >
                </TextField>
              </FormControl>
              <FormControl className="formInputs" fullWidth>
                <TextField className={classes.paddingTop}
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  inputProps={{maxLength: 255}}
                >
                </TextField>
              </FormControl>
              <FormControl className="formInputs" fullWidth>
                <TextField className={classes.paddingTop}
                  disabled
                  disableUnderline
                  InputProps={{disableUnderline: true}}
                  id="organisation"
                  label="Organisation"
                  name="organisation"
                  variant="standard"
                  onChange={formik.handleChange}
                  value={formik.values.organisation}
                >
                </TextField>
              </FormControl>
              <Tooltip placement="bottom-start" title="Only fill this field if u want to change your password!">
                <FormControl className="formInputs formPasswordInput" fullWidth>
                  <InputLabel className="passwordLabel" htmlFor="password" shrink="true">New password</InputLabel>
                  <Input className="passwordInput"
                    id="password"
                    name="password"
                    type={values.showPassword ? 'text' : 'password'}
                    variant="standard"
                    placeholder="New password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  >
                  </Input>
                </FormControl>
              </Tooltip>
              { waiting ?
              <Typography align='center'>
                <CircularProgress className={classes.top}/>
              </Typography> :
              <Typography className="submitButtonContainer" align='center'>
                <StyledButton
                  type="submit"
                  className="button-block submitButton"
                >
                  Save
                </StyledButton>
              </Typography>
              }

            </form>
          </Grid>
        </div>
        <DropzoneDialogBase
          dialogTitle={''}
          acceptedFiles={['.jpg', '.png', '.jpeg']}
          fileObjects={files}
          cancelButtonText={'cancel'}
          submitButtonText={'submit'}
          maxFileSize={8000000}
          filesLimit={1}
          open={openFileDialog}
          onAdd={(newFile) => {
            addFile(newFile);
          }}
          onDelete={(deleteFile) => {
            removeFile(deleteFile);
          }}
          onClose={() => setOpenFileDialog(false)}
          onSave={() => {
            setOpenFileDialog(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        />
      </Box>
    </Modal>
  );
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 4,
  maxHeight: '80%',
  overflow: 'auto',
  fontFamily: 'Quicksand',
};

const useStyles = makeStyles(() => ({
  padding: {
    paddingTop: '20px',
    paddingLeft: '40px',
    paddingRight: '40px',
  },
  itemCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  paddingTop: {
    marginTop: '20px',
    width: '100%',
  },
  top: {
    marginTop: '20px',
  },
}));

const StyledButton = withStyles({
  root: {
    backgroundColor: '#F0F0F0',
    borderRadius: '90px',
    width: '30%',
    padding: '10px',
    marginTop: '20px',
  },
})(Button);

export default ProfileModal;
