import React, {useContext, useEffect, useState} from 'react';
import {makeStyles, IconButton, Box, Typography, Modal, Grid, FormControl, InputLabel, Select, MenuItem, TextField, Button} from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ProjectsContext from '../../context/ProjectsContext';
import EpicsContext from '../../context/EpicsContext';
import AlertContext from '../../context/AlertContext';
import ProjectQuestionairesContext from '../../context/ProjectQuestionairesContext';
import {getProjectQuestionaires, getProjectEpics} from '../../requests/ProjectRequests';
import {getQuestionaireFields, addQuestionaireFields} from '../../requests/QuestionaireRequests';
import {addIssue} from '../../requests/IssueRequests';
import {withStyles} from '@material-ui/core/styles';
import {useFormik} from 'formik';
import {DropzoneArea} from 'material-ui-dropzone';
import {ReactComponent as CustomarrowDown} from '../../customArrowDown.svg';
import InputAdornment from '@mui/material/InputAdornment';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CircularProgress from '@mui/material/CircularProgress';
import '../../css/GlobalStyle.css';

function IssueModal({visible, onCancel, projectFilterValue, epicFilterValues}) {
  const projectsContext = useContext(ProjectsContext);
  const epicsContext = useContext(EpicsContext);
  const projectQuestionairesContext = useContext(ProjectQuestionairesContext);
  const [project, setProject] = useState([]);
  const [epics, setEpics] = useState([]);
  const [epic, setEpic] = useState([]);
  const [questionaires, setQuestionaires] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [questionaire, setQuestionaire] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [platform, setPlatform] = useState([]);
  const [fields, setFields] = useState([]);
  const [files, setFiles] = useState([]);
  const [initialValues, setInitialValues] = useState();
  const {alert} = useContext(AlertContext);
  const [waiting, setWaiting] = useState(false);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    onSubmit: (values) => {
      setWaiting(true);
      handleSubmit(values);
    },
  });

  useEffect(async () => {
    if (projectFilterValue != null && projectFilterValue != project) {
      clearstates(true, true);
      setProject(projectFilterValue);
      if (epicsContext.allEpics.length) {
        const filterEpics = epicsContext.allEpics.filter((filteredEpic) => filteredEpic.project_id === projectFilterValue);
        setEpics(filterEpics);
      }
    }
    if (epicFilterValues.length == 1) {
      if (epicFilterValues != epic) {
        setProject(projectFilterValue);
        setEpic(epicFilterValues);
        setQuestionaires(projectQuestionairesContext.questionaires);
        for (const questionaire of projectQuestionairesContext.questionaires) {
          setPlatforms((platforms) => [...platforms, questionaire.platform]);
        }
      }
    } else {
      clearstates(false, true);
    }
  }, [projectFilterValue, epicFilterValues]);

  useEffect(async () => {
    if (epic.length == 1) {
      if (projectQuestionairesContext.questionaires.length >= 1) {
        setPlatforms([]);
        setQuestionaires(projectQuestionairesContext.questionaires);
        for (const questionaire of projectQuestionairesContext.questionaires) {
          setPlatforms((platforms) => [...platforms, questionaire.platform]);
        }
      }
    } else {
      clearstates(false, true);
    }
  }, [projectQuestionairesContext.questionaires]);

  const clearstates = (isNeeded, removeEpic) => {
    if (removeEpic) {
      if (isNeeded) {
        setProject([]);
        setEpics([]);
      }
      setEpic([]);
      setPlatforms([]);
    }
    setPlatform([]);
    setQuestionaire([]);
    setFields([]);
    setFiles([]);
    const values = {};
    setInitialValues(values);
  };

  const handleSelectProject = async (event) => {
    clearstates(true, true);
    setProject(event.target.value);
    loadEpics(event.target.value);
  };

  const loadEpics = async (projectId) => {
    const epicsData = await getProjectEpics(projectId, alert);
    setEpics(epicsData.projectUnArchivedEpics);
    if (epicsData.projectUnArchivedEpics.length == 0) {
      alert({
        title: `There are no epics available for this project`,
        severity: 'warning',
      });
    }
  };

  const handleSelectEpic = async (event) => {
    setEpic(event.target.value);
    if (!platforms.length) {
      const questionaireData = await getProjectQuestionaires(project, alert);
      setQuestionaires(questionaireData);
      for (const questionaire of questionaireData) {
        setPlatforms((platforms) => [...platforms, questionaire.platform]);
      }
    }
  };

  const handleSelectPlatform = async (event) => {
    setPlatform(event.target.value);
    for (const questionaire of questionaires) {
      if (questionaire.platform.id == event.target.value) {
        setQuestionaire(questionaire);
        const questionaireFieldsData = await getQuestionaireFields(questionaire.id, alert);
        const values = {};
        questionaireFieldsData.forEach((field) => {
          values[field.name] = '';
        });
        values['subject'] = '';
        values['description'] = '';
        setInitialValues(values);
        setFields(questionaireFieldsData);
      }
    }
  };

  const handleUpload = (files) =>{
    setFiles(files);
  };

  const handleSubmit = async (values) => {
    const response = await addIssue(values, files, epic, project, alert);
    if (response != null) {
      const fieldsResponse = await addQuestionaireFields(response.data.issue.id, values, fields, alert);
      if (fieldsResponse != null) {
        alert({
          title: `Issue successfully created`,
          severity: 'success',
        });
        clearstates(false, false);
      }
      setWaiting(false);
    }
  };

  return (
    <Modal
      open={visible}
      onClose={onCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box id="issuePopup">
        <Grid container direction="row" justifyContent='space-between'>
          <Typography className={classes.header}>
                Create issue
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon/>
          </IconButton>
        </Grid>
        <br></br>
        <Box sx={{width: '100%'}}>
          <FormControl variant="standard">
            <Typography>Project</Typography>
            <InputLabel id="demo-simple-select-label">Project</InputLabel>
            <StyledSelect className={classes.select}
              IconComponent={CustomarrowDown}
              disableUnderline
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue="Project"
              value={project}
              label="Project"
              onChange={handleSelectProject}
              displayEmpty
              MenuProps={
                {classes: {paper: 'dropDownList'}}
              }
            >
              {projectsContext.projects && Object.values(projectsContext.projects).map((project) => (
                <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
          <FormControl variant="standard">
            <Typography>Epic</Typography>
            <InputLabel disabled={epics.length == 0} id="demo-simple-select-label">Epic</InputLabel>
            <StyledSelect className={classes.select}
              IconComponent={CustomarrowDown}
              disableUnderline
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={epic}
              label="Epic"
              onChange={handleSelectEpic}
              disabled={epics.length == 0}
              MenuProps={
                {classes: {paper: 'dropDownList'}}
              }
            >
              {epics && Object.values(epics).map((epic) => (
                <MenuItem key={epic.id} value={epic.id}>{epic.name}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>

          <FormControl variant="standard">
            <Typography>Platforms</Typography>
            <InputLabel disabled={platforms.length == 0 || epic == 0} id="demo-simple-select-label">Platform</InputLabel>
            <StyledSelect className={classes.select}
              IconComponent={CustomarrowDown}
              disableUnderline
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={platform}
              label="Platform"
              onChange={handleSelectPlatform}
              disabled={platforms.length == 0 || epic == 0}
              MenuProps={
                {classes: {paper: 'dropDownList'}}
              }
            >
              {platforms && Object.values(platforms).map((platform) => (
                <MenuItem key={platform.id} value={platform.id}>{platform.name}</MenuItem>
              ))}
            </StyledSelect>
          </FormControl>
        </Box>
        <br></br>
        <form onSubmit={formik.handleSubmit} className={classes.maxWidth}>
          <Grid container direction="row" spacing={2} justifyContent='center'>
            <Grid item xs={12} sm={12} className={classes.itemStart}>
              <Box className={classes.maxWidth}>
                {fields.length ?
                  <FormControl fullWidth className={classes.marginTop}>
                    <Typography className="formTitles">Title</Typography>
                    <StyledTextField
                      id="subject"
                      label="Title"
                      name="subject"
                      variant="standard"
                      inputProps={{maxLength: 255}}
                      // disableUnderline
                      // InputProps={{disableUnderline: true}}
                      required
                      onChange={formik.handleChange}
                      value={formik.values['subject']}
                    >
                    </StyledTextField>
                    <Typography className="formTitles">Description</Typography>
                    <StyledTextArea className={classes.marginTop | 'multiLine-textField'}
                      id="description"
                      label="Description"
                      name="description"
                      multiline
                      minRows={4}
                      variant="standard"
                      required
                      // disableUnderline
                      // InputProps={{disableUnderline: true}}
                      onChange={formik.handleChange}
                      value={formik.values['description']}
                    >
                    </StyledTextArea>
                  </FormControl> :
                  <span></span>
                }
                {fields.length ?
                  <FormControl fullWidth className={classes.marginTop && 'dropzoneContainer'}>
                    <Typography className="formTitles">Upload file</Typography>
                    <DropzoneArea
                      acceptedFiles={['.jpg', '.mp4', '.png', '.jpeg', '.mov']}
                      dropzoneClass={classes.dropZone | 'dropzoneHover'}
                      onChange={handleUpload.bind(this)}
                      filesLimit={5}
                      showPreviews={true}
                      useChipsForPreview={true}
                      previewText={'Files'}
                      inputProps={{classes: {root: 'previewChip'}}}
                      previewChipProps={{classes: {root: 'previewChip'}}}
                      showPreviewsInDropzone={false}
                      maxFileSize={8000000}
                      showAlerts={true}
                      alertSnackbarProps={{anchorOrigin: {vertical: 'top', horizontal: 'center'},
                        classes: {root: 'alertContainer'}}}
                    />
                  </FormControl> :
                  <span></span>
                }
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.itemEnd}>
              <Box className={classes.maxWidth}>
                {fields.length ? Object.values(fields).map((field) => (
                  renderSwitch(field, formik)
                )) : <span></span> }
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              {fields.length ?
              <div className="submitContainer">
                { waiting ?
                  <Typography align='center'>
                    <CircularProgress/>
                  </Typography> :
                  <Typography align='center'>
                    <StyledButton
                      type="submit"
                      className="button-block"
                    >
                    Submit
                    </StyledButton>
                  </Typography>
                }
              </div>:
                <span></span>
              }
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};

function renderSwitch(param, formik) {
  const name = param.name;

  switch (param.field_type) {
    case 'select':
      return <FormControl variant="standard" fullWidth style={{marginTop: '10px'}} key={name}>
        <Typography className="formTitles">{param.name}</Typography>
        <InputLabel>{param.name}</InputLabel>
        <StyledSelect style={{borderRadius: '80px'}} key={name}
          className="extrafieldsSelect"
          IconComponent={CustomarrowDown}
          id={name}
          label={name}
          name={name}
          // disableUnderline
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name]}
          MenuProps={
            {classes: {paper: 'dropDownList'}}
          }
        >
          {param.input && Object.values(param.input).map((input) => (
            <MenuItem key={input} value={input}>{input}</MenuItem>
          ))}
        </StyledSelect>
      </FormControl>;
    case 'text':
      return <FormControl fullWidth style={{marginTop: '10px'}} key={name}>
        <Typography className="formTitles">{param.name}</Typography>
        <StyledTextField key={name}
          id={name}
          label={name}
          name={name}
          inputProps={{maxLength: 255}}
          // disableUnderline
          InputProps={{disableUnderline: false,
            startAdornment: (
              name.includes('laat') ?
              <InputAdornment position="start">
                <AccessTimeRoundedIcon className="timeIcon" fontSize="small" />
              </InputAdornment>:
              ''
            )}}
          variant="standard"
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name]}
        >
        </StyledTextField>
      </FormControl>;
    case 'textarea':
      return <FormControl className="textareaMultiline" fullWidth style={{marginTop: '10px'}} key={name}>
        <Typography className="formTitles">{param.name}</Typography>
        <StyledTextArea key={name}
          id={name}
          label={name}
          name={name}
          className="multiLine-textField"
          multiline
          // disableUnderline
          // InputProps={{disableUnderline: true}}
          minRows={6}
          variant="standard"
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name]}
        >
        </StyledTextArea>
      </FormControl>;
    case 'number':
      return <FormControl fullWidth style={{marginTop: '10px'}} key={name}>
        <Typography className="formTitles">{param.name}</Typography>
        <StyledTextField key={name}
          id={name}
          label={name}
          name={name}
          type="number"
          // disableUnderline
          // InputProps={{disableUnderline: true}}
          variant="standard"
          required={!!param.required}
          onChange={formik.handleChange}
          value={formik.values[name]}
        >
        </StyledTextField>
      </FormControl>;
    default:
      return <span></span>;
  }
};

const useStyles = makeStyles(() => ({
  dropZone: {
    minHeight: '150px',
  },
  select: {
    borderRadius: '80px',
  },
  itemStart: {
    display: 'flex',
    alignItems: 'top',
  },
  itemEnd: {
    display: 'flex',
    alignItems: 'top',
  },
  header: {
    alignSelf: 'center',
    fontSize: '20px',
    fontFamily: 'Quicksand',
  },
  control: {
    margin: '3px',
    width: '32%',
  },
  maxWidth: {
    width: '100%',
  },
  marginTop: {
    marginTop: '10px',
  },
}));

const StyledTextField = withStyles({
  root: {
    'backgroundColor': '#F0F0F0',
    'borderRadius': '80px',
    'fontFamily': 'Quicksand',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        'borderRadius': '80px',
      },
    },
    '& .MuiInputBase-input': {
      'fontFamily': 'Quicksand',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      'borderRadius': '80px',
    },
  },
})(TextField);

const StyledTextArea = withStyles({
  root: {
    'backgroundColor': '#F0F0F0',
    'borderRadius': '20px',
    'fontFamily': 'Quicksand',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        'borderRadius': '20px',
      },
    },
    '& .MuiInputBase-input': {
      'fontFamily': 'Quicksand',
    },
    '& .MuiOutlinedInput-input:-webkit-autofill': {
      'borderRadius': '80px',
    },
  },
})(TextField);

const StyledSelect = withStyles({
  root: {
    'backgroundColor': '#F0F0F0',
    'borderRadius': '80px',
    'fontFamily': 'Quicksand',
    '& MuiOutlinedInput-root': {
      '& fieldset': {
        'borderRadius': '80px',
      },
    },
    '&:focus': {
      'borderRadius': '80px',
    },
  },
})(Select);

const StyledButton = withStyles({
  root: {
    backgroundColor: '#F0F0F0',
    borderRadius: '90px',
    width: '30%',
    padding: '10px',
    marginTop: '10px',
  },
})(Button);

export default IssueModal;
